import React from 'react';
import { Link } from 'react-router-dom';

import DotIcon from '../components/dotIcon'


function BlogEleDao(props) {
    return (
        <div className='post-details'>
            <section className="page-title" />
            <section className="tf-section detail">
                <div className="container">
                    <div className="row">
                        <article className="article" data-aos="fade-in" data-aos-duration="800">
                            <div className="post p-b23">
                                <h4 className="title"><Link to="#">Element DAO Proposer</Link></h4>
                            </div>
                            <div className="content-details mb-41">
                                <p>1.The proposers are the first batch of people who have the privilege to initiate a proposal and let Element DAO members vote.</p>
                                <p className="st2">2.Who Can Become the First Proposer?</p>
                                <ul className="list">
                                    <li><DotIcon />
                                        <span> Founder and core members of projects within the NFT ecosystem.</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Well-known public chains, wallets, and infrastructures in the Blockchain space.</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> KOLs with creative abilities in social media such as Twitter, Medium, Notion, Reddit, etc.</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Well-known NFT creators.</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Well-known NFT collectors.</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Well-known blue-chip asset holders.</span>
                                    </li>
                                </ul>
                                <p>Note: All Element DAO leaders will be selected from among the Element Official NFT holders.</p>
                                <p className="st2">3.How to apply the first proposer?</p>
                                <p>Application Link:<a href="https://docs.google.com/forms/d/e/1FAIpQLSf03o7BjeuIP9fpl2XpIRJlKnCZqgh1WTEV8DGeAuB8e0Uxcw/viewform?usp=sf_link" target='_blank' rel="noreferrer">
                                        https://docs.google.com/forms/d/e/1FAIpQLSf03o7BjeuIP9fpl2XpIRJlKnCZqgh1WTEV8DGeAuB8e0Uxcw/viewform?usp=sf_link</a>
                                </p>
                                <p>4.All Element DAO proposers will be selected from among the Element Official NFT holders.</p>
                            </div>
                        </article>         
                    </div>
                </div>
            </section>
        </div>
        
    );
}

export default BlogEleDao;