import React from 'react';
import { Link } from 'react-router-dom';

import img from '../assets/images/post/elemetas.svg'


function BlogEleDao(props) {
    return (
        <div className='post-details'>
            <section className="page-title">
            </section>
            <section className="tf-section detail">
                <div className="container">
                    <div className="row">
                        <article className="article" data-aos="fade-in" data-aos-duration="800">
                            <div className="post p-b23">
                                <h4 className="title"><Link to="#">Element Pass Genesis Story</Link></h4>
                                <ul className="meta-post">
                                    <li><span>by</span>Element DAO</li>
                                    <li>21 Mar 2023</li>
                                </ul>  
                            </div>
                                <div className="image mb-39">
                                    <img src={img} alt="" />
                                </div>
                            <div className="content-details">
                                <h6>Background</h6>
                                <p>The nature of the world is fractal.</p>
                                <p>In the physical world, the moment of the Big Bang gave birth to the universe, and the elements of hydrogen and helium that multiplied and evolved as the first born of the chaotic world continued to fuse and evolve, eventually forming the diverse elements that make up our beautiful world. In the crypto world, the emergence of Bitcoin is also like the Big Bang, opening up a new decentralized world, which is a crossing of time and space, while the basic standards such as ERC20 and ERC721 are like hydrogen and oxygen, the basic elements that make up the world. With the continuous innovation, splitting and mutation of technology, these elements keep building an infinite number of meta-universe elements. It is conceivable that in this new world, human beings will be born for the second time.</p>
                                <p>The evolution of both worlds is a process of entropy increase, which cannot be reversed by humans, so we need to create an "Earth" like home in the new world. Element Pass Genesis has a powerful energy to survive, it will open the source of life for you and help you build a new home.</p>
                            </div>
                            <div className="content-details">
                                <h6>Element Pass Genesis' Web3 dream</h6>
                                <p>We all know that the largest production outcomes require the coordination of the largest group, which in turn yields the greatest economies of scale. Team size increases as these outcomes increase, and capital efficiency decreases as team size increases. This is the conundrum, can we get great productive results without being tied to structure? The answer is yes.</p>
                                <p>The promise of Web3 is to create Internet services that do not require permission according to the principle of decentralization, i.e., the victory of the platform is the victory of the individuals involved, and vice versa. The main advantage of moving from Web2 to Web3 service offerings is that the fate of the platform and its community are intertwined, as there is no intermediary to absorb the value generated. In Web3, you get this feedback loop where the value created is completely redistributed to the people who created it, so they create more. Creativity is unleashed, the value of the platform is unleashed on a massive scale, and the "pie" gets bigger.</p>
                                <p>Element Pass Genesis' Web3 dream is to be able to extend teamwork without reducing capital efficiency, to provide a working, highly liquid marketplace around transactions, to empower the community through Element DAO, to drive more creators to participate in building, to create the greatest synergy, and ultimately to achieve true decentralization, and we believe that the code rules based on proof of workload will continue to We believe that the code rules based on proof of work will continue to put "rewards" into the hands of contributors, and the growth flywheel of value generation will start.</p>
                            </div>
                            <div className="content-details">
                                <h6>Element Pass Genesis Overview</h6>
                                <p className="st2">Every Element Pass Genesis is unique, just like every human being has a unique life path and values. And these different Element Pass Genesis combine and interact to create infinite possibilities and values. Just like the self-similarity of fractals, each tiny element can influence the whole, and the existence of each Element Pass Genesis adds a unique value to this world.</p>
                                <p>Element Pass Genesis are the messengers of this metaverse, and they are born with the characteristics of the elemental family lineage, which determines their abilities and traits. Some contain extremely rare attributes that make them inherently extraordinary, while others have ordinary attributes, but they possess the same 3-fold characteristics that make them seem to stand out. Each Element Pass Genesis native has the exclusive rights and benefits of the Element platform, allowing you to swim in the future Web3 world, which will be the soul binding of various rights and benefits in the future.</p>
                                <p>Let's explore this magical digital world together, across the future, embrace Element Pass Genesis and start our exploration journey.</p>
                            </div>
                            <div className="wrap-widget">
                                <div className="widget widget-tag style-2" />
                                <div className="widget widget-social style-2">
                                    <h6 className="title-widget">Share:</h6>
                                    <ul>
                                        <li>
                                            <a href={`https://twitter.com/share?text=${`Elemetas`}&url=${encodeURI('https://www.eledao.io/elemetas-details')}`} target="_blank" rel="noreferrer">
                                                <i className="fab fa-twitter"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </article>         
                    </div>
                </div>
            </section>
        </div>
        
    );
}

export default BlogEleDao;