const dataActivity = [
    {
        id: 1,
        title: '1. Guaranteed Allowlist',
        desc: "",
        // link: 'https://www.element.market',
        list: [
            {
                text: '· Element multi-chain users (buying volume > 0.2 ETH (or equivalent usdc), users will be given priority in qualifying according to their loyalty ranking);'
            },
            {
                text: '· Influencers;'
            },
            {
                text: '· Apply <a href="/apply">here</a>;'
            },
            {
                text: 'Tips: all mint wallets should have at least 0.2 ETH (24hs before mint); first come, first serve.'
            },
        ]
    },
    {
        id: 2,
        title: '2. Mint Allowlist',
        desc: "",
        // class: 'active',
        // link: 'https://docs.google.com/forms/d/e/1FAIpQLSeDTvHVxSt9Yb6_1L2q9tKqvPo8kgglbEUnCwmoD--S4Paozw/viewform?usp=sf_link',
        list: [
            {
                text: '· Element multi-chain users (should at least bought one NFT before, users will be given priority in qualifying according to their loyalty ranking);'
            },
            {
                text: '· Community cooperations;'
            },
            {
                text: '· Apply <a href="/apply">here</a>;'
            },
            {
                text: 'Tips: all mint wallets should have at least 0.15 ETH (24hs before mint); first come, first serve.'
            },
        ]
    },
    {
        id: 3,
        title: '3. Public Mint',
        desc: "The last chance to mint without any permission.",
        link: ''
    },
    // {
    //     id: 4,
    //     title: '4. Buy Element Official NFT',
    //     desc: "Apply Element Pass whitelist, don't miss out, join now for early access.",
    //     link: '/nft'
    // }
]

export default dataActivity;