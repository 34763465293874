import React , {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import { Modal } from "react-bootstrap";
import './styles.scss'
import Popup from '../../popup';

SliderItem.propTypes = {
    item : PropTypes.object,
};

function SliderItem(props) {
    const {item} = props;

    const [modalShow, setModalShow] = useState(false);


    useEffect(() => {
        if (modalShow) {
            setTimeout(() => {
                setModalShow(false) 
            }, 3000);
        }
    }, [modalShow])
    

    return (
        <div className={`box-slider ${item.classAction}`}>
            {/* <div className='bg-slider' style={{ backgroundImage: `url(${item.bgImg}) ` }} alt="cybox" /> */}
            <div className="box-slider__main">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7 col-md-12">
                            <div className="content-box" style={{marginBootem: '50px'}}>
                                <h1 className="title">{item.title}</h1>
                                {/* <p className="sub-title">{item.desc}</p> */}
                                <div className="wrap-btn">
                                    {/* <a href="#joinus" style={{width: '230px', marginBottom: '18px'}}  className="tf-button-st2 btn-effect popup-youtube">
                                        <span className="effect">Join Element DAO</span>
                                    </a>
                                    <a href="#about" className="tf-button btn-effect" style={{ width: '230px', cursor: 'pointer', marginBottom: '18px', border: '1px' }} data-toggle="modal" data-target="#popup_bid">
                                        <span className="boder-fade"></span>
                                        <span className="effect">About Element DAO</span>
                                    </a> */}
                                    {/* <Link to={'/nft'} className="tf-button-st2 btn-effect popup-youtube" style={{ width: '230px', marginBottom: '18px' }} data-toggle="modal" data-target="#popup_bid">
                                        <span className="boder-fade"></span>
                                        <span className="effect">Mint</span>
                                    </Link>
                                    <a href="https://www.element.market/" style={{width: '230px', marginBottom: '18px'}}  className="tf-button btn-effect" target="_blank" rel="noreferrer">
                                        <span className="boder-fade"></span>
                                        <span className="effect">Go ELement</span>
                                    </a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Popup modalShow={modalShow} setModalShow={() => {setModalShow(!modalShow)}} />

            {/* <Modal
                show={modalShow}
                onHide={setModalShow}
            >
            <Modal.Header closeButton></Modal.Header>

                <div className="modal-body center">
                                <div className="box-wallet-inner">
                                    <div className="sc-box">
                                        <div className="img">
                                            <img src="assets/images/common/icon-1.png" alt="Crybox" />
                                        </div>
                                        <h6 className="heading"><a href="#">Meta Mask</a> </h6>
                                        <p className="content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                                    </div>
                                    <div className="sc-box">
                                        <div className="img">
                                            <img src="assets/images/common/icon-6.png" alt="Crybox" />
                                        </div>
                                        <h6 className="heading"><a href="#"> Bitski</a></h6>
                                        <p className="content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                                    </div>
                                    <div className="sc-box">
                                        <div className="img">
                                            <img src="assets/images/common/vector.png" alt="Crybox" />
                                        </div>
                                        <h6 className="heading"><a href="#">Fortmatic</a> </h6>
                                        <p className="content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                                    </div>
                                    <div className="sc-box">
                                        <div className="img">
                                            <img src="assets/images/common/icon-2.png" alt="Crybox" />
                                        </div>
                                        <h6 className="heading"><a href="#">Coinbase Wallet</a> </h6>
                                        <p className="content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                                    </div>
                                    <div className="sc-box">
                                        <div className="img">
                                            <img src="assets/images/common/icon-3.png" alt="Crybox" />
                                        </div>
                                        <h6 className="heading"><a href="#">Authereum</a> </h6>
                                        <p className="content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                                    </div>
                                    <div className="sc-box">
                                        <div className="img">
                                            <img src="assets/images/common/icon-4.png" alt="Crybox" />
                                        </div>
                                        <h6 className="heading"><a href="#">Kaikas</a> </h6>
                                        <p className="content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                                    </div>
                                </div>  
                            </div>
            </Modal> */}

            {/* <div className="modal fade popup" id="popup_bid" tabindex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="header-popup">
                        <h5>Connect to a wallet</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    
                    
                </div>
            </div>
        </div> */}
        </div>
    );
}

export default SliderItem;