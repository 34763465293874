import React from 'react';
import PageTitle from '../components/pagetitle';
import Work from '../features/work/home-v2';
import dataWork from '../assets/fake-data/data-work';



function FAQ(props) {
    return (
        <div>
            <PageTitle title='Feature' />

            <Work data={dataWork} />
        </div>
    );
}

export default FAQ;