import React, {useState, useEffect, useCallback} from 'react';

import Popup from '../../components/popup'
import '../../scss/components/nft-img.scss'
import cardIcon from '../../assets/images/nft/nft-standard.gif'
import AboutItem from '../../features/activity/about-item';
import dataActivity from '../../assets/fake-data/data-activity';
import dataBenefitsStandard from '../../assets/fake-data/data-benefits-standard';
import Participate from './participate'
import CheckAllowList from './checkAllowList'
import MintDetail from './mintDetail'
import Apply from './apply'


function NftItemDetails(props) {
    
    
    const [modalShow, setModalShow] = useState(false);
    useEffect(() => {
        if (modalShow) {
            setTimeout(() => {
                setModalShow(false) 
            }, 3000);
        }
    }, [modalShow])

    return (
        <div className='page-item-details'>
            <section className="tf-section tf-item-details pb-mobie">
                <div className="container nft-container">
                    <div className="row container-row">
                        <div className="col-xl-6 col-lg-6 col-md-12 tf-item-details-left">
                            <div className="image-details" data-aos="fade-right" data-aos-duration="800">
                                <div className='nft-img-box'>
                                    <div className='card'>
                                        <img className='card-img' src={cardIcon} alt='' />
                                        <span className='card-h'>Coming soon</span>
                                    </div>
                                </div>
                            </div>
                            <div className='attributes' data-aos="fade-right" data-aos-duration="800">
                                {
                                    dataBenefitsStandard.map(item => (
                                        <div key={item.id} className="col-xl-4 col-lg-4 col-md-12">
                                            <div className='attributes-item-box'>
                                                <div className={`box-text corner-box`}>
                                                    <img className='image' src={item.img} alt='' />
                                                    <div className="h8">{item.title}</div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12">
                            <div className="item-details" data-aos="fade-left" data-aos-duration="800">
                                <h6>Element Pass Standard</h6>
                                <p className="desc">
                                Element Pass Standard(EPS) is a standard collection(amount <b style={{color: '#fff'}}>20,000</b>) in the Element Pass Series. It is an equity asset anchored based on Element Pass Genesis(EPG). Holders will have most of the similar rights and interests as EPG.<br /><br />
                                EPS is anchored by the long-term development of the Element platform and has a value from the moment of Mint. In the future, its price may fluctuate with the macro environment of the encryption market, user scale, and market sentiment.
                                </p>
                                {/* <CheckAllowList /> */}
                                <Apply />
                                {/* <a href="https://docs.google.com/forms/d/e/1FAIpQLSek-QxbufgW8IAFs1UPqhhOMTJZ6k2DnTPE0YDNiuddGhrKPQ/viewform?usp=sf_link" target="_blank" className="tf-button btn-effect" rel="noreferrer"> */}
                                {/* <div className='price-box'>
                                    <a className="tf-button btn-effect" target='_blank' rel="noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSd3szt5MFqzyXn0s44PLmBKUFicewzpyDPHUfwDsJTYJz83JQ/viewform">
                                        <span className="boder-fade"></span>
                                        <span className="effect">Apply guaranteed allowlist</span>
                                    </a>
                                </div> */}
                                {/* <MintDetail /> */}
                                {/* <Participate /> */}
                            </div>
                        </div>
                    </div>
                    <div className="row container-row">
                        <div className="col-md-12">
                            <div className="content-about">
                                <div className="tf-title">
                                    <h4 className="title">How to get a chance to Mint?</h4>
                                </div>
                                <p>Only Element Pass holders have the initial access to become Element DAO member, get one Element Pass as soon as possible.</p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="wrap-about">
                                {
                                    dataActivity.map(item => (
                                        <AboutItem key={item.id} item={item} />
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row container-row">
                        <div className="col-md-12">
                            <div className="content-about mobie-40">
                                <div className="tf-title">
                                    <h4 className="title">Mint Schedule for April 14, 2023</h4>
                                </div>
                                {/* <p className='center pd10'>Click here to check which list you’re on.</p> */}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className='row' style={{marginBottom: '20px'}}>
                                <div className="col-md-6">TIME (SINGAPORE TIME)</div>
                                <div className="col-md-6">MINT OPENS FOR</div>
                            </div>
                            <div className='row box-text corner-box'>
                                <div className="col-md-6">
                                    <div className="h7">7:00 PM SGT</div>
                                    <p>(11:00 AM UTC)</p>
                                </div>
                                <div className="col-md-6">
                                    <div className="h7">Guaranteed Allowlist</div>
                                    <p>WL Price: <b style={{color: '#fff'}}>0.045</b> ETH<br />
                                    Wallets that won 4 guaranteed spots. Show up anytime in this window and easily mint your pass. If you don’t mint during this time, your pass may be claimed by someone on the mint allowlist.</p>
                                </div>
                            </div>
                            <div className='row box-text corner-box'>
                                <div className="col-md-6">
                                    <div className="h7">9:00 PM SGT</div>
                                    <p>(1:00 PM UTC)</p>
                                </div>
                                <div className="col-md-6">
                                    <div className="h7">Mint Allowlist</div>
                                    <p>WL Price: <b style={{color: '#fff'}}>0.045</b> ETH<br />
                                    Wallets that won 3 guaranteed spots. Wallets that pre-registered on the Element Mint Allowlist, but didn’t win a guaranteed spot, can mint during this time. First come, first serve.</p>
                                </div>
                            </div>
                            <div className='row box-text corner-box'>
                                <div className="col-md-6">
                                    <div className="h7">11:00 PM SGT</div>
                                    <p>(3:00 PM UTC)</p>
                                </div>
                                <div className="col-md-6">
                                    <div className="h7">Public</div>
                                    <p>Public Price: <b style={{color: '#fff'}}>0.06</b> ETH<br />
                                    3 spots at most per wallet. Any wallet is eligible to mint during this period.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <Popup modalShow={modalShow} setModalShow={() => {setModalShow(!modalShow)}} />
        </div>
    );
}

export default NftItemDetails;