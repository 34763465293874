import React from "react"
import { Modal } from "react-bootstrap";

import './index.scss'

const Popup = ({modalShow, setModalShow}) => {
    return (
         <Modal
            show={modalShow}
            onHide={setModalShow}
        >
            <div className="modal-body center">
                <p className="content">Comming Soon</p>
            </div>
        </Modal>
    )
}

export default Popup