import React, {useState, useCallback, useEffect, useMemo} from 'react';
import Modal from 'react-bootstrap/Modal';
// import { useAccount } from "wagmi";
import { useConnectWallet } from '@web3-onboard/react'
import {
    useConnectModal
  } from '@rainbow-me/rainbowkit';
import CircleLoading from '../../components/icons/CircleLoading'
import Elemetas from '../../elemetas/elemetas'
import { Link } from 'react-router-dom';
import modalbg from '../../assets/images/common/shine.svg'

const CheckAllowList = () => {
    const [{ wallet }, connect] = useConnectWallet()

    const address = useMemo(() => wallet?.accounts[0].address, [wallet])
    const balance = useMemo(() => wallet?.accounts[0].balance, [wallet])

    const [checkLoading, setCheckLoading] = useState(false)
    const [inAllowList, setInAllowList] = useState(false)
    const [inWhiteList, setInWhiteList] = useState(false)
    const [modalShow, setModalShow] = useState(false)

    useEffect(() => {
        setInAllowList(false)
        setInWhiteList(false)
        setCheckLoading(false)
        setModalShow(false)
    }, [address])

    const check = useCallback(async () => {
        if(checkLoading) return
        if(!address) {
            connect()
            return
        }
        setCheckLoading(true)
        let allowListMintCount = Elemetas.getAllowListMintCount(address)
        let whiteListMintCount = Elemetas.getWhiteListMintCount(address)
        if(!allowListMintCount && !whiteListMintCount) {
            await Elemetas.updateWhiteListConfigs(address).catch((error) => console.log(error))
            // userConfig = Elemetas.getUserConfig(address)
            allowListMintCount = Elemetas.getAllowListMintCount(address)
            whiteListMintCount = Elemetas.getWhiteListMintCount(address)
        }
        if(allowListMintCount > 0) {
            setInAllowList(true)
        } else if (whiteListMintCount > 0) {
            setInWhiteList(true)
        }
        setModalShow(true)
        setCheckLoading(false)
    }, [checkLoading, address, connect])

    useEffect(() => {
        if(modalShow && (inAllowList || inWhiteList)) {
            for(let i=0; i<100; i++) {
                // Random rotation
                var randomRotation = Math.floor(Math.random() * 360);
                // Random width & height between 0 and viewport
                var randomWidth = Math.floor(Math.random() * Math.max(document.documentElement.clientWidth, window.innerWidth || 0));
                var randomHeight =  Math.floor(Math.random() * Math.max(document.documentElement.clientHeight, window.innerHeight || 0));
                
                // Random animation-delay
                var randomAnimationDelay = Math.floor(Math.random() * 2);
              
                // Random colors
                var colors = ['#0CD977', '#FF1C1C', '#FF93DE', '#5767ED', '#FFC61C', '#8497B0']
                var randomColor = colors[Math.floor(Math.random() * colors.length)];
              
                // Create confetti piece
                var confetti = document.createElement('div');
                confetti.className = 'confetti';
                confetti.style.top=randomHeight + 'px';
                confetti.style.left=randomWidth + 'px';
                confetti.style.backgroundColor=randomColor;
                confetti.style.transform='skew(15deg) rotate(' + randomRotation + 'deg)';
                confetti.style.animationDelay=randomAnimationDelay + 's';
                document.querySelectorAll(".check-allowList-modal")[0]?.appendChild(confetti);
              }
        }
    }, [modalShow, inAllowList, inWhiteList])

    return (
        <div className='check-allowList'>
            <button className="tf-button btn-effect" onClick={() => check()}>
                <span className="boder-fade"></span>
                <span className="effect">
                    {checkLoading ? <CircleLoading /> : 'Final Allowlist Check'}
                </span>
            </button>
            <Link to='/apply' className="tf-button btn-effect green">
                <span className="boder-fade"></span>
                <span className="effect">
                    Apply
                </span>
            </Link>

            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="lg"
                centered
                className='check-allowList-modal2'
            >
                <img className='check-allowList-modal-bg' src={modalbg} alt='' />
                <Modal.Header closeButton closeVariant='white'>
                </Modal.Header>
                <Modal.Body>
                    <div className='check-allowList-modal-content'>
                        <p className='check-allowList-modal-content-text'>
                            {inAllowList ? <>Congratulations！<br/>You’re eligible for guaranteed allowlist.</> : 
                            inWhiteList ? <>Congratulations！<br/>You’re eligible for mint allowlist.</> : 
                            <>Ops...<br/>Allowlist will be continuously released, keep trading and ensuring enough ETH in your wallet to get permission.</>}
                        </p>
                        <div className='check-allowList-modal-content-bg2' />
                        <button className="tf-button btn-effect" onClick={() => setModalShow(false)}>
                            <span className="boder-fade"></span>
                            <span className="effect">CONFIRM</span>
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default CheckAllowList