import React from 'react';
import PageTitle from '../components/pagetitle';
import About from '../features/about/home-v3';
import dataAbout from '../assets/fake-data/data-about';



function FAQ(props) {
    return (
        <div>
            <PageTitle title='Benefits' />

            <About data={dataAbout} />
        </div>
    );
}

export default FAQ;