import React, {useState, useEffect, useCallback, useMemo} from 'react';

import Accordion from 'react-bootstrap/accordion';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { postElementPassSignUp } from '../../api/index.js'
import CircleLoading from '../../components/icons/CircleLoading'
import Modal from 'react-bootstrap/Modal';
import { ethers } from 'ethers'
import { toast } from 'react-toastify';
import tipsIcon from '../../assets/images/icons/icon-tips.svg'
import iconEmail from '../../assets/images/icons/icon-email.svg'
import iconTwitter from '../../assets/images/icons/icon-twitter.svg'
import iconWallet from '../../assets/images/icons/icon-wallet.svg'
import iconPersonal from '../../assets/images/icons/icon-personal.svg'

const isEmail = (value) => {
    let reg = /^[0-9a-zA-Z_.-]+[@][0-9a-zA-Z_.-]+([.][a-zA-Z]+){1,2}$/;
    return reg.test(value)
}
const splitTwitter = (str) => {
    const arr = str.split('?')[0].split('/')
    return arr[arr.length-1] || arr[arr.length-2]
}

const Apply = () => {
    const [checkLoading, setCheckLoading] = useState(false)
    const [applyType, setApplyType] = useState(1)
    const [modalShow, setModalShow] = useState(false)
    const [email, setEmail] = useState('')
    const [twitter, setTwitter] = useState('')
    const [address, setAddress] = useState('')
    const [intro, setIntro] = useState('')

    const modalShowFn = useCallback((type) => {
        setApplyType(type)
        setModalShow(true)
    }, [])
    const modalHideFn = useCallback((type) => {
        setModalShow(false)
        setEmail('')
        setTwitter('')
        setAddress('')
        setIntro('')
        setCheckLoading(false)
    }, [])

    const submit = useCallback(async () => {
        console.log(email, twitter, address, intro)
        if(email && !isEmail(email)) {
            toast.error('Please enter the correct email address.')
            return
        }
        if(!address) {
            toast.error('Please enter wallet address.')
            return
        }
        if(!ethers.utils.isAddress(address)) {
            toast.error('Enter the correct wallet address.')
            return
        }
        setCheckLoading(true)
        const res = await postElementPassSignUp({
            accountAddress: address,
            email,
            twitterHandle: splitTwitter(twitter),
            profile: intro,
            guaranteedStep: applyType
        }).catch((error) => console.log(error))
        if(res?.code === 0) {
            const data = res.data
            console.log(data)
            if(data) {
                toast.success('Apply successful!')
            } else {
                toast.error('Apply failed.')
            }
        } else if (res?.code === 10061) {
            toast.error('Insufficient transaction volume.')
        } else if (res?.code === 10062) {
            toast.error('Insufficient wallet balance.')
        } else {
            toast.error(res?.msg || 'Apply failed.')
        }
        setCheckLoading(false)
    }, [address, applyType, email, intro, twitter])

    return (
        <div className='apply'>
            <div className='flat-accordion'>
                <Accordion defaultActiveKey='0'>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            Guaranteed AllowList 
                            <OverlayTrigger
                                placement='top'
                                overlay={
                                    <Tooltip id={`tooltip-apply`}>
                                        1. Own platform volume statistics currently only statistics active purchase nft (pending orders to sell statistics temporarily not counted still under development)<br />
                                        2. Aggregated purchases OpenSea and Looks platform are included in the volume statistics (blur aggregated purchases are not added to the statistics)
                                    </Tooltip>
                                }
                            >
                                <img className='tips-icon' src={tipsIcon} alt='' onClick={(e) => e.stopPropagation()} />
                            </OverlayTrigger>
                        </Accordion.Header>
                        <Accordion.Body>
                            <ul>
                                <li className='check-item'>
                                    · Buying volume &gt; 0.2 ETH
                                </li>
                                <li className='check-item'>
                                    · Wallet balance &gt; 0.2 ETH
                                </li>
                            </ul>
                            <button className="tf-button btn-effect" onClick={() => modalShowFn(1)}>
                                <span className="boder-fade"></span>
                                <span className="effect">apply</span>
                            </button>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            <div className='flat-accordion'>
                <Accordion  defaultActiveKey='0'>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            Mint Allowlist
                            <OverlayTrigger
                                placement='top'
                                overlay={
                                    <Tooltip id={`tooltip-apply`}>
                                        1. Own platform volume statistics currently only statistics active purchase nft (pending orders to sell statistics temporarily not counted still under development)<br />
                                        2. Aggregated purchases OpenSea and Looks platform are included in the volume statistics (blur aggregated purchases are not added to the statistics)
                                    </Tooltip>
                                }
                            >
                                <img className='tips-icon' src={tipsIcon} alt='' onClick={(e) => e.stopPropagation()} />
                            </OverlayTrigger>
                        </Accordion.Header>
                        <Accordion.Body>
                            <ul>
                                <li className='check-item'>
                                    · Buying volume &gt; 0 ETH
                                </li>
                                <li className='check-item'>
                                    · Wallet balance &gt; 0.15 ETH
                                </li>
                            </ul>
                            <button className="tf-button btn-effect" onClick={() => modalShowFn(2)}>
                                <span className="boder-fade"></span>
                                <span className="effect">apply</span>
                            </button>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            <Modal
                show={modalShow}
                onHide={modalHideFn}
                size="lg"
                centered
                className='pass-apply-modal'
            >
                <Modal.Header closeButton closeVariant='white'>
                    <p>Please fill in your information</p>
                </Modal.Header>
                <Modal.Body>
                    <table>
                        <tr>
                            <td className='icon'><img src={iconEmail} alt='' /></td>
                            <td><input type='text' placeholder='Email' onChange={(v) => setEmail(v.target.value?.trim()) } /></td>
                        </tr>
                        <tr>
                            <td className='icon'><img src={iconTwitter} alt='' /></td>
                            <td><input type='text' placeholder='Twitter' onChange={(v) => setTwitter(v.target.value?.trim()) } /></td>
                        </tr>
                        <tr>
                            <td className='icon'><img src={iconWallet} alt='' /></td>
                            <td><input type='text' placeholder='Wallet Address' onChange={(v) => setAddress(v.target.value?.trim()) } /></td>
                        </tr>
                        <tr>
                            <td className='icon personal'><img src={iconPersonal} alt='' /></td>
                            <td><textarea placeholder='Personal Introduction' onChange={(v) => setIntro(v.target.value?.trim()) } /></td>
                        </tr>
                    </table>
                    <button className="tf-button btn-effect" onClick={submit}>
                        <span className="boder-fade"></span>
                        <span className="effect">{checkLoading ? <CircleLoading /> : 'CONFIRM'}</span>
                    </button>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Apply