import React,{useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './styles.scss'
import Popup from '../../../components/popup';

BlogItem.propTypes = {
    item: PropTypes.object,
};

function BlogItem(props) {
    const {item} = props;

    const [modalShow, setModalShow] = useState(false);
    useEffect(() => {
        if (modalShow) {
            setTimeout(() => {
                setModalShow(false) 
            }, 3000);
        }
    }, [modalShow])

    return (
        <div className="post-item">
            <Link to={item.link || ''} className="wrap" onClick={() => {
                if(!item.link) {setModalShow(true)}
            }}>
                <div className="tag">{item.category}</div>
                <div className="image">
                    <img src={item.img} alt="Crybox" />
                </div>
                <div className="content-item">
                    <div className="content">
                        <div className="meta-post">
                            <a href="/blog">{item.time}</a>
                        </div>
                        <div className="h7 title">{item.title}</div>
                        <p>{item.text}</p>
                    </div>
                </div>
            </Link>
            <Popup modalShow={modalShow} setModalShow={() => {setModalShow(!modalShow)}} />
        </div>
    );
}

export default BlogItem;