// https://fkhadra.github.io/react-toastify/installation

import {
  toast,
  ToastContainer,
  Bounce,
  Flip,
  // Slide,
  Zoom,
  cssTransition,
  collapseToast,
  useToastContainer,
  useToast,
  ToastContent,
  ToastOptions
} from 'react-toastify'

// 样式重置
import './index.scss'

const resetToastErrorParentStyle = () => {
  const $toastError = document.querySelectorAll('.element-toast-error')
  console.log($toastError)
  $toastError.forEach((node) => {
    if (node) {
      node.setAttribute('style', 'display: flex')

      if (node.parentNode)
        (node.parentNode).setAttribute(
          'style',
          'top: 60px; left: 0; transform: translateX(0); padding: 0; width: 100%;'
        )
    }
  })
}
export const toastError = (content, options) => {
  toast.error(content, {
    ...options,
    className: 'element-toast-error',
    autoClose: false,
    onOpen: () => {
      resetToastErrorParentStyle()
    }
  })
}

const Default = {
  CSS_NAMESPACE: 'ToastifyElement'
}
const Slide = cssTransition({
  enter: `${Default.CSS_NAMESPACE}--animate ${Default.CSS_NAMESPACE}__slide-enter`,
  exit: `${Default.CSS_NAMESPACE}--animate ${Default.CSS_NAMESPACE}__slide-exit`,
  appendPosition: true
})

export { toast, ToastContainer, Bounce, Flip, Slide, Zoom, cssTransition, collapseToast, useToastContainer, useToast }
