import { isObject, merge } from 'lodash'
import qs from 'qs';
import hmacSHA256 from 'crypto-js/hmac-sha256'

let baseURL = '',
    appKeyTmp = '',
    appSecretTmp = '',
    env = process.env.NODE_ENV || 'development',
    inital = {
        method: 'GET',
        params: null,
        body: null,
        headers: {},
        cache: 'no-cache',
        credentials: 'include',
        responseType: 'JSON'
    };

switch (env) {
    case 'development':
        baseURL = 'https://api.element.market';
        appKeyTmp = 'zQbYj7RhC1VHIBdWU63ki5AJKXloamDT'
        appSecretTmp = 'UqCMpfGn3VyQEdsjLkzJv9tNlgbKFD7O'
        break;
    case 'production':
        baseURL = 'https://api.element.market';
        appKeyTmp = 'zQbYj7RhC1VHIBdWU63ki5AJKXloamDT'
        appSecretTmp = 'UqCMpfGn3VyQEdsjLkzJv9tNlgbKFD7O'
        break;
    default:
        baseURL = 'https://api.element.market';
        appKeyTmp = 'ysBokbA3gKUzt61DmeHWjTFYZ07CGPQL'
        appSecretTmp = 'a2PAJXRBChdpGvoyKEz3lLS5Yf1bM0NO'
        break;
}

const getAPISign = (key, secret) => {
    const AppKey = key || appKeyTmp
    const AppSecret = secret || appSecretTmp
  
    // 随机数字字母，建议4位
    const nonce = Number.parseInt((Math.random() * (9999 - 1000 + 1) + 1000).toString(), 10)
    // 当前时间戳（秒）
    const timestamp = Number.parseInt((Date.now() / 1000).toString(), 10)
    // 使用appSecret进行HMacSha256加密函数
    const hmac256 = hmacSHA256(`${AppKey}${nonce}${timestamp}`, AppSecret)
  
    const headers = {
      'X-Api-Key': AppKey,
      'X-Api-Sign': `${hmac256}.${nonce}.${timestamp}`
    }
  
    return headers
  }

export default function request(url, config) {
    // init params
    if (typeof url !== 'string') throw new TypeError('url must be required and of string type');
    if (!isObject(config)) config = {};
    config = merge(true, {}, inital, config);
    let {
        method = 'get',
        params,
        body,
        headers,
        cache,
        credentials,
        responseType
    } = config;

    // 处理URL：请求前缀 & 问号参数
    if (!/^http(s?):\/\//i.test(url)) url = baseURL + url;
    if (params != null) {
        if (isObject(params)) params = qs.stringify(params);
        url += `${url.includes('?')?'&':'?'}${params}`;
    }

    // 根据自己的需求来:body传递的是普通对象，我们今天项目需要传递给服务器的是URLENCODED格式，我们才处理它的格式；如果用户传递的本身就不是普通对象(例如:文件流、字符串、FORM-DATA...)，还是以用户自己写的为主...
    if (isObject(body)) {
        headers['Content-Type'] = 'application/json';
        body = JSON.stringify(body);
    } else if (typeof body === 'string') {
        try {
            // 是JSON字符串
            body = JSON.parse(body);
            headers['Content-Type'] = 'application/json';
        } catch (err) {
            // 不是JSON字符串:可以简单粗暴的按照URLECCODED格式字符串处理
            headers['Content-Type'] = 'application/json';
        }
    }

    // 把config配置成为fetch需要的对象
    config = {
        method: method.toUpperCase(),
        headers: {
            ...headers,
            ...getAPISign()
        },
        credentials,
        cache
    };
    if (/^(POST|PUT|PATCH)$/i.test(method) && body != null) config.body = body;

    // 发送请求
    return fetch(url, config).then(response => {
        let {
            status,
            statusText
        } = response;
        // 只要状态码是以2或者3开始的，才是真正的获取成功
        if (status >= 200 && status < 400) {
            let result;
            switch (responseType.toUpperCase()) {
                case 'JSON':
                    result = response.json();
                    break;
                case 'TEXT':
                    result = response.text();
                    break;
                case 'BLOB':
                    result = response.blob();
                    break;
                case 'ARRAYBUFFER':
                    result = response.arrayBuffer();
                    break;
                default:
                    result = response.json();
                    break;
            }
            return result;
        }
        return Promise.reject({
            code: 'STATUS ERROR',
            status,
            statusText
        });
    }).catch(reason => {
        if (reason && reason.code === 'STATUS ERROR') {
            // @1 状态码错误
            // switch (reason.status) {
            //     case 400:
            //         // ...
            //         break;
            //     case 401:
            //         // ...
            //         break;
            //     case 404:
            //         // ...
            //         break;
            // }
        } else if (!navigator.onLine) {
            // @2 网络中断
            // ...
        } else {
            // @3 请求被终止
            // ...
        }
        return Promise.reject(reason);
    });
};