import image51 from '../images/ecosystem/51.png'
import image75 from '../images/ecosystem/75.png'
import image89 from '../images/ecosystem/89.png'
import image106 from '../images/ecosystem/106.png'
import image177 from '../images/ecosystem/177.png'
import image180 from '../images/ecosystem/180.png'
import image190 from '../images/ecosystem/190.png'
import image199 from '../images/ecosystem/199.png'
import image200 from '../images/ecosystem/200.png'
import image201 from '../images/ecosystem/201.png'
import image281 from '../images/ecosystem/281.png'
import image282 from '../images/ecosystem/282.png'
import image361 from '../images/ecosystem/361.png'
import image380 from '../images/ecosystem/380.png'
import image402 from '../images/ecosystem/402.jpg'
import image478 from '../images/ecosystem/478.png'
import image491 from '../images/ecosystem/491.png'
import image492 from '../images/ecosystem/492.png'
import image499 from '../images/ecosystem/499.png'
import image515 from '../images/ecosystem/515.png'
import image529 from '../images/ecosystem/529.png'
import image542 from '../images/ecosystem/542.png'
import image555 from '../images/ecosystem/555.png'
import image574 from '../images/ecosystem/574.png'
import image582 from '../images/ecosystem/582.png'
import image589 from '../images/ecosystem/589.png'
import image637 from '../images/ecosystem/637.png'
import image678 from '../images/ecosystem/678.png'
import image696 from '../images/ecosystem/696.png'
import image792 from '../images/ecosystem/792.png'
import image904 from '../images/ecosystem/904.png'
import image956 from '../images/ecosystem/956.png'
import image962 from '../images/ecosystem/962.png'
import image1088 from '../images/ecosystem/1088.png'
import image1153 from '../images/ecosystem/1153.png'
import image1279 from '../images/ecosystem/1279.png'
import image1285 from '../images/ecosystem/1285.png'
import image1432 from '../images/ecosystem/1432.png'
import image1450 from '../images/ecosystem/1450.png'
import image1504 from '../images/ecosystem/1504.png'
import image1538 from '../images/ecosystem/1538.png'
import image1579 from '../images/ecosystem/1579.png'
import image1509 from '../images/ecosystem/1509.png'
import image1615 from '../images/ecosystem/1615.png'
import image1661 from '../images/ecosystem/1661.png'
import image1666 from '../images/ecosystem/1666.png'
import image1681 from '../images/ecosystem/1681.png'
import image1760 from '../images/ecosystem/1760.png'
import image1808 from '../images/ecosystem/1808.png'
import image1853 from '../images/ecosystem/1853.png'
import image1883 from '../images/ecosystem/1883.png'
import image1956 from '../images/ecosystem/1956.png'
import image1967 from '../images/ecosystem/1967.png'
import image2076 from '../images/ecosystem/2076.png'
import image2128 from '../images/ecosystem/2128.png'
import image2158 from '../images/ecosystem/2158.png'
import image2225 from '../images/ecosystem/2225.png'
import image2280 from '../images/ecosystem/2280.png'
import image2295 from '../images/ecosystem/2295.png'
import image2306 from '../images/ecosystem/2306.png'
import image2358 from '../images/ecosystem/2358.png'
import image2439 from '../images/ecosystem/2439.png'
import image2486 from '../images/ecosystem/2486.png'

const dataEcosysteam = [
    {
        id: 43,
        image: image574,
        name: 'CyberConnect',
        link: 'https://twitter.com/CyberConnectHQ',
        tokenId: '#574'
    },
    {
        id: 41,
        image: image89,
        name: 'Polyhedra',
        link: 'https://twitter.com/PolyhedraZK',
        tokenId: '#89'
    },
    {
        id: 19,
        image: image200,
        name: 'TokenPocket',
        link: 'https://twitter.com/TokenPocket_TP',
        tokenId: '#200'
    },
    {
        id: 13,
        image: image1279,
        name: 'Foresight News',
        link: 'https://twitter.com/Foresight_News',
        tokenId: '#1279'
    },
    {
        id: 24,
        image: image515,
        name: 'Ultiverse',
        link: 'https://twitter.com/UltiverseDAO',
        tokenId: '#515'
    },
    {
        id: 42,
        image: image1760,
        name: 'GeniiData',
        link: 'https://twitter.com/GeniiData',
        tokenId: '#1760'
    },
    {
        id: 20,
        image: image696,
        name: '链捕手',
        link: 'https://twitter.com/ChainCatcher_',
        tokenId: '#696'
    },
    {
        id: 1,
        image: image190,
        name: 'Debox',
        link: 'https://twitter.com/DeBox_Social',
        tokenId: '#190'
    },
    // {
    //     id: 8,
    //     image: image499,
    //     name: 'Star Protocol',
    //     link: 'https://twitter.com/star_protocol',
    //     tokenId: '#499'
    // },
    {
        id: 29,
        image: image2306,
        name: 'SERAPH',
        link: 'https://twitter.com/Seraph_crypto',
        tokenId: '#2306'
    },
    {
        id: 33,
        image: image1883,
        name: 'MetaCeneGame',
        link: 'https://twitter.com/MetaCeneGame',
        tokenId: '#1883'
    },
    {
        id: 38,
        image: image2295,
        name: 'Owlto',
        link: 'https://twitter.com/Owlto_Finance',
        tokenId: '#2295'
    },
    {
        id: 2,
        image: image380,
        name: 'BabySwap',
        link: 'https://twitter.com/babyswap_bsc',
        tokenId: '#380'
    },
    {
        id: 37,
        image: image201,
        name: '4everland',
        link: 'https://twitter.com/4everland_org',
        tokenId: '#201'
    },
    {
        id: 15,
        image: image956,
        name: 'Gameta',
        link: 'https://twitter.com/Gameta_Official',
        tokenId: '#956'
    },
    {
        id: 44,
        image: image361,
        name: 'FatPay',
        link: 'https://twitter.com/realFatPay',
        tokenId: '#361'
    },
    {
        id: 21,
        image: image491,
        name: 'ORC20DAO',
        link: 'https://twitter.com/Ordinals_Orc20',
        tokenId: '#491'
    },
    {
        id: 9,
        image: image1285,
        name: 'Abyss',
        link: 'https://twitter.com/AbyssWorldHQ',
        tokenId: '#1285'
    },
    {
        id: 3,
        image: image51,
        name: 'BabyWealthyClub',
        link: 'https://twitter.com/BabyWealthyClub',
        tokenId: '#51'
    },
    {
        id: 50,
        image: image1088,
        name: 'Dechat',
        link: 'https://twitter.com/dechat_io',
        tokenId: '#1088'
    },
    {
        id: 53,
        image: image478,
        name: 'BlastPenguins',
        link: 'https://twitter.com/BlastPenguins',
        tokenId: '#478'
    },
    {
        id: 46,
        image: image180,
        name: 'Reiki',
        link: 'https://twitter.com/Reiki_Web3Go',
        tokenId: '#180'
    },
    {
        id: 28,
        image: image1666,
        name: 'VIP3',
        link: 'https://twitter.com/web3vip',
        tokenId: '#1666'
    },
    {
        id: 58,
        image: image904,
        name: 'Influpia',
        link: 'https://twitter.com/influpia',
        tokenId: '#904'
    },
    {
        id: 40,
        image: image1661,
        name: 'Anome',
        link: 'https://twitter.com/Anome_Official',
        tokenId: '#1661'
    },
    {
        id: 55,
        image: image106,
        name: 'Merlin Code',
        link: 'https://twitter.com/merlincodexyz',
        tokenId: '#106'
    },
    {
        id: 6,
        image: image1432,
        name: 'Plant Crossing',
        link: 'https://twitter.com/PlantCrossing22',
        tokenId: '#1432'
    },
    {
        id: 49,
        image: image589,
        name: 'TownStory',
        link: 'https://twitter.com/townstorygamefi',
        tokenId: '#589'
    },
    {
        id: 4,
        image: image582,
        name: 'MixVerse',
        link: 'https://twitter.com/Mixverse_',
        tokenId: '#582'
    },
    {
        id: 59,
        image: image2439,
        name: 'SingularLabs',
        link: 'https://twitter.com/0xSingularLabs',
        tokenId: '#2439'
    },
    {
        id: 48,
        image: image75,
        name: 'AneeMate',
        link: 'https://twitter.com/playaneemate',
        tokenId: '#75'
    },
    {
        id: 5,
        image: image282,
        name: 'PLUR',
        link: 'https://twitter.com/PLUR_DAO',
        tokenId: '#282'
    },
    {
        id: 7,
        image: image637,
        name: 'Kepler',
        link: 'https://twitter.com/KeplerHomes',
        tokenId: '#637'
    },
    {
        id: 10,
        image: image1681,
        name: 'Attic',
        link: 'https://twitter.com/Atticnovelnft',
        tokenId: '#1681'
    },
    {
        id: 11,
        image: image281,
        name: 'Legend of Arcadia',
        link: 'https://twitter.com/LegendofArcadia',
        tokenId: '#281'
    },
    {
        id: 12,
        image: image1538,
        name: 'Yuri AI',
        link: 'https://twitter.com/YuriAI_Official',
        tokenId: '#1538'
    },
    {
        id: 16,
        image: image1853,
        name: 'zksync_doge',
        link: 'https://twitter.com/zksync_doge',
        tokenId: '#1853'
    },
    {
        id: 18,
        image: image177,
        name: 'Club3',
        link: 'https://twitter.com/club3xyz',
        tokenId: '#177'
    },
    {
        id: 22,
        image: image1450,
        name: 'GoodLuckPandas',
        link: 'https://twitter.com/GoodLuckPandas',
        tokenId: '#1450'
    },
    {
        id: 23,
        image: image492,
        name: 'DA_AGE',
        link: 'https://twitter.com/DA_AGE_CN',
        tokenId: '#492'
    },
    {
        id: 25,
        image: image542,
        name: '星海漫游',
        link: 'https://twitter.com/Seaxing777',
        tokenId: '#542'
    },
    {
        id: 27,
        image: image1615,
        name: 'ValTower',
        link: 'https://twitter.com/ValTower_Game',
        tokenId: '#1615'
    },
    {
        id: 30,
        image: image678,
        name: 'zkPengz',
        link: 'https://twitter.com/zkPengz',
        tokenId: '#678'
    },
    {
        id: 31,
        image: image1153,
        name: 'Gametacus',
        link: 'https://twitter.com/Gametacus2023',
        tokenId: '#1153'
    },
    {
        id: 34,
        image: image2225,
        name: 'Nootie',
        link: 'https://twitter.com/nootcoinbnb',
        tokenId: '#2225'
    },
    {
        id: 45,
        image: image1967,
        name: 'Beoble',
        link: 'https://twitter.com/beoble_official',
        tokenId: '#1967'
    },
    {
        id: 47,
        image: image1956,
        name: 'Carv',
        link: 'https://twitter.com/carv_official',
        tokenId: '#1956'
    },
    {
        id: 51,
        image: image555,
        name: 'Cardrona',
        link: 'https://twitter.com/Cardrona_io',
        tokenId: '#555'
    },
    // {
    //     id: 52,
    //     image: image2280,
    //     name: 'Simpdog',
    //     link: 'https://twitter.com/Simpdoge_me',
    //     tokenId: '#2280'
    // },
    {
        id: 54,
        image: image529,
        name: 'Blast Birbs',
        link: 'https://twitter.com/blastbirbs',
        tokenId: '#529'
    },
    {
        id: 56,
        image: image1579,
        name: 'Merlin Monsters',
        link: 'https://twitter.com/MerlinMonsters',
        tokenId: '#1579'
    },
    {
        id: 57,
        image: image2158,
        name: 'Nonoku',
        link: 'https://twitter.com/nonoku_nft',
        tokenId: '#2158'
    },
    {
        id: 60,
        image: image402,
        name: 'Revomon',
        link: 'https://twitter.com/RevomonVR/status/1773043812744516030',
        tokenId: '#402'
    },
    {
        id: 199,
        image: image199,
        name: 'Ascendant',
        link: 'https://twitter.com/Ascendant_fun',
        tokenId: '#199'
    },
    {
        id: 1808,
        image: image1808,
        name: 'Degen Alpha',
        link: 'https://twitter.com/DegenAlphaxyz',
        tokenId: '#1808'
    },
    {
        id: 2486,
        image: image2486,
        name: 'Ether Pillar',
        link: 'https://twitter.com/EtherPillarNFT',
        tokenId: '#2486'
    },
    {
        id: 2076,
        image: image2076,
        name: 'GridPuppets',
        link: 'https://twitter.com/GridPuppets',
        tokenId: '#2076'
    },
    {
        id: 2128,
        image: image2128,
        name: 'Skytopia',
        link: 'https://twitter.com/SkytopiaLabs',
        tokenId: '#2128'
    },
    {
        id: 1504,
        image: image1504,
        name: 'UXLINK',
        link: 'https://twitter.com/UXLINKofficial',
        tokenId: '#1504'
    },
    {
        id: 2358,
        image: image2358,
        name: 'DINO',
        link: 'https://x.com/Linea_erc404',
        tokenId: '#2358'
    },
    {
        id: 499,
        image: image499,
        name: 'XStar',
        link: 'https://x.com/xstar_id',
        tokenId: '#499'
    },
    {
        id: 962,
        image: image962,
        name: 'ZNS',
        link: 'https://x.com/ZNSConnect',
        tokenId: '#962'
    },
    {
        id: 1509,
        image: image1509,
        name: 'Genify',
        link: 'https://x.com/GenifyHQ',
        tokenId: '#1509'
    },
    {
        id: 792,
        image: image792,
        name: 'Bitmon',
        link: 'https://x.com/bitmon_ai',
        tokenId: '#792'
    },
    {
        id: 2280,
        image: image2280,
        name: 'Polyflow',
        link: 'https://x.com/Polyflow_Tech',
        tokenId: '#2280'
    },
]

export default dataEcosysteam;