import React , {useState} from 'react';
import PropTypes from 'prop-types';
import '../styles.scss'
import AboutItem from '../about-item';
import Button from '../../../components/button';

About.propTypes = {
    data : PropTypes.array,
};

function About(props) {

    const {data} = props;

    const [dataBlock] = useState(
        {
            subtitle : 'Overview',
            title: 'Element Pass Overview',
            desc : "Element Pass is a series NFT collections for Element DAO members and Element users. Holders have the opportunity to participate the revolutionary creativity that Element may bring to Web3, meanwhile, recevie super rewards by accompanying and using Element Web3 products."
        }
    )

    return (
        <section className="tf-section tf-about" id='feature'>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="content-about mobie-40 mb-60" data-aos="fade-up" data-aos-duration="800">
                            <div className="tf-title">
                                <p className="h8 sub-title">{dataBlock.subtitle}</p>
                                <h4 className="title">{dataBlock.title}</h4>
                            </div>
                            <p className='center pd10'>{dataBlock.desc}</p>
                        </div>
                    </div>

                        {
                            data.map(item => (
                                <div key={item.id} className="col-xl-4 col-lg-4 col-md-12" data-aos="fade-up" data-aos-delay="100" data-aos-duration="800">
                                    <AboutItem item={item} />
                                </div>
                            ))
                        }
                    <div className="col-md-12 table-data">
                        <div className="content-about mobie-40 mb-60" data-aos="fade-up" data-aos-duration="800">
                            <div className="tf-title">
                                <h5 className="title">ELEMENT NFT Equity Flywheel</h5>
                            </div>
                        </div>
                        <div className='row' data-aos="fade-up" data-aos-duration="800">
                            <div className="col-xl-4 col-lg-4 col-md-4">
                                <div className="h7 box-text corner-box"></div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4">
                                <div className="h7 box-text corner-box">EPS</div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4">
                                <div className="h7 box-text corner-box">EPG</div>
                            </div>
                        {/* </div>
                        <div className='row'> */}
                            <div className="col-xl-4 col-lg-4 col-md-4">
                                <div className="h8 box-text corner-box">Defination</div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4">
                                <div className="box-text corner-box">Standard Utility NFT Pass for Element Users</div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4">
                                <div className="box-text corner-box">Genesis Utility NFT Pass for Element Users</div>
                            </div>
                        {/* </div>
                        <div className='row'> */}
                            <div className="col-xl-4 col-lg-4 col-md-4">
                                <div className="h8 box-text corner-box">Utilities</div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4">
                                <div className="box-text corner-box">Govern & Staking & Eco-Airdrops & Product Privilages</div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4">
                                <div className="box-text corner-box">Govern & Staking & Eco-Airdrops & Product Privilages</div>
                            </div>
                        {/* </div>
                        <div className='row'> */}
                            <div className="col-xl-4 col-lg-4 col-md-4">
                                <div className="h8 box-text corner-box">Explanation</div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4">
                                <div className="box-text corner-box">Growing Beneficial NFT Pass with User Loyalty Growth</div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4">
                                <div className="box-text corner-box">Genesis NFT Pass as Value Anchor of Platform Equity.</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{marginTop: '30px'}}>
                        <div className="btn-about center" data-aos="fade-up" data-aos-duration="800">
                            <Button title='get Nfts' path='/nft' />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;