import React from "react";

const CircleLoading = () => {
    return (
        // <svg viewBox="22 22 44 44">
        //     <circle
        //         className="circle-loading-svg"
        //         cx="44"
        //         cy="44"
        //         r="20.2"
        //         fill="none"
        //         strokeWidth={4}
        //     />
        // </svg>
        <span className="circle-loading-svg"></span>
    )
}

export default CircleLoading