import Home from "./HomeN";
// import HomeOne from "./HomeOne";
import HomeTwo from "./HomeTwo";
import HomeThree from "./HomeThree";
import AboutOne from "./AboutOne";
import AboutTwo from "./AboutTwo";
import ComingSoon from "./ComingSoon";
import Community from "./Community";
import FAQ from "./FAQ";
import Page404 from "./404";
import RoadMapOne from "./RoadMapOne";
import RoadMapTwo from "./RoadMapTwo";
import NftItem from "./NftItem";
import NftItemDetails from "./NftItemDetails";
import NftItemDetailsCopy from "./NftItemDetailsCopy";
import NftItemDetailsCheck from "./NftItemDetailsCheck";
import Blog from "./Blog";
import BlogDetails from "./BlogDetails";
import Contact from "./Contact";
import TeamDetail from "./TeamDetail";
import Term from './Term'
import Privacy from './Privacy'
import EledaoDetails from './BlogEledao'
import BlogAirdrop from './BlogAirdrop'
import BlogJoinUs1 from './BlogJoinUs1'
import BlogJoinUs2 from './BlogJoinUs2'
import BlogJoinUs3 from './BlogJoinUs3'
import BlogJoinUs4 from './BlogJoinUs4'
import Feature from './Feature'
import Benefits from './Benefits'
import ElemetasDetails from './Elemetas'
import Distribution from './Distribution'


const routes = [
  { path: '/', component: <Home />},
  { path: '/home-v2', component: <HomeTwo />},
  { path: '/home-v3', component: <HomeThree />},
  { path: '/about-v1', component: <AboutOne />},
  { path: '/about-v2', component: <AboutTwo />},
  { path: '/coming-soon', component: <ComingSoon />},
  { path: '/community', component: <Community />},
  { path: '/faq', component: <FAQ />},
  { path: '/404', component: <Page404 />},
  { path: '/road-map-v1', component: <RoadMapOne />},
  { path: '/road-map-v2', component: <RoadMapTwo />},
  { path: '/nft-item', component: <NftItem />},
  { path: '/nft', component: <NftItemDetailsCopy />},
  { path: '/apply', component: <NftItemDetails />},
  { path: '/check', component: <NftItemDetailsCheck />},
  { path: '/blog', component: <Blog />},
  { path: '/blog-details', component: <BlogDetails />},
  { path: '/contact', component: <Contact />},
  { path: '/team-details', component: <TeamDetail />},
  { path: '/tos', component: <Term />},
  { path: '/privacy', component: <Privacy />},
  // { path: '/airdrop', component: <BlogAirdrop /> },
  { path: '/eledao-details', component: <EledaoDetails /> },
  { path: '/elemetas-details', component: <ElemetasDetails /> },
  { path: '/eledao-member', component: <BlogJoinUs1 /> },
  { path: '/eledao-proposer', component: <BlogJoinUs2 /> },
  { path: '/eledao-verified-creator', component: <BlogJoinUs3 /> },
  { path: '/eledao-volunteer', component: <BlogJoinUs4 /> },
  { path: '/feature', component: <Feature /> },
  { path: '/benefits', component: <Benefits /> },
  // { path: '/distribution', component: <Distribution /> },
]

export default routes;