const dataAbout = [
    {
        id: 1,
        title: 'Governance',
        desc: "Engage in voting on governance matters and the deployment of ecosystem resources."
        // class: 'active'
    },
    {
        id: 2,
        title: 'Incentive',
        desc: "Motivate DAO members, users of ecosystem projects, and builders."
    },
    // {
    //     id: 3,
    //     title: 'Empower users',
    //     desc: "More utility NFT Launchpad will empower all users."
    // },
]

export default dataAbout;