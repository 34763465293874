import {BigNumber, ethers} from "ethers";
import fetch from "../api/fetch";
import {queryAbi} from "./abis/elemetasHelperAbis";
import {JsonRpcProvider, Web3Provider} from "@ethersproject/providers";
import {launchpadAbi} from "./abis/launchpadAbis";

const isMainNet = process.env.NODE_ENV === 'production';
// const isMainNet = true;
const launchpadAddr = isMainNet ? '0x4cc4752155877f4333f25bb9a6f8880567ee1231' : '0x96a47c19cc35289d316061f75d290758a6f38e8c';
const queryAddr = isMainNet ? '0x0d23d201eb5fb940ee4c0901386653f378ec353c' : '0x5c2ff514ee574d0b5d308984a355a507de98246b';

const chainId = isMainNet ? '0x1' : '0x61';
const fetchUrl = isMainNet ?
    '/mapi/qualified/launchpad/forms' :
    'https://api-test.element.market/mapi/qualified/launchpad/forms';

const nullAddr = '0x0000000000000000000000000000000000000000';
const userConfigs = new Map();

const allowListSaleStart = isMainNet ? 1681470000000 : 1681458020000;
const whiteListSaleStart = isMainNet ? 1681477200000 : 1681458820000;
const publicSaleStart = isMainNet ? 1681484400000 : 1681458920000;
const publicSaleEnd = 1704038400000;

export default class Elemetas {

    static Phase = {
        NotStartedPhase: 0, // 未开始
        AllowListPhase: 1,  // allowList阶段
        WhiteListPhase: 2,  // whiteList阶段
        PublicSalePhase: 3, // 公售阶段
        EndedPhase: 4,      // 售卖已结束
    }

    static Configs = {
        maxSupply: 20000, // 售卖总量
        totalSupply: 0,  // 已售数量
        allowListPhase: {
            launchpadId: '0xd82a4387',
            slotId: '0',
            startTime: allowListSaleStart,
            endTime: whiteListSaleStart,
            price: ethers.utils.parseEther('0.045'), // BigNumber
            priceBaseETH: 0.045,
        },
        whiteListPhase: {
            launchpadId: '0xc5baab29',
            slotId: '0',
            startTime: whiteListSaleStart,
            endTime: publicSaleStart,
            price: ethers.utils.parseEther('0.045'), // BigNumber
            priceBaseETH: 0.045,
        },
        publicSalePhase: {
            launchpadId: '0x0387d5fa',
            slotId: '0',
            startTime: publicSaleStart,
            endTime: publicSaleEnd,
            price: ethers.utils.parseEther('0.06'), // BigNumber
            priceBaseETH: 0.06,
        },
        freeMint: {
            launchpadId: '0x88eee586',
            slotId: '0',
            startTime: allowListSaleStart,
            endTime: publicSaleEnd,
        }
    }

    // 获取当前阶段，返回值：Elemetas.Phase.xxx
    static getCurrentPhase() {
        const t = Date.now()
        if (
            t >= Elemetas.Configs.allowListPhase.startTime &&
            t < Elemetas.Configs.allowListPhase.endTime
        ) {
            return Elemetas.Phase.AllowListPhase;
        } else if (
            t >= Elemetas.Configs.whiteListPhase.startTime &&
            t < Elemetas.Configs.whiteListPhase.endTime
        ) {
            return Elemetas.Phase.WhiteListPhase;
        } else if (
            t >= Elemetas.Configs.publicSalePhase.startTime &&
            t < Elemetas.Configs.publicSalePhase.endTime
        ) {
            return Elemetas.Phase.PublicSalePhase;
        } else if (t >= Elemetas.Configs.publicSalePhase.endTime) {
            return Elemetas.Phase.EndedPhase;
        } else {
            return Elemetas.Phase.NotStartedPhase;
        }
    }

    // 获取当前阶段，用户最大mint数量
    static getMaxMintCount(userAddress) {
        const config = Elemetas.getUserConfigInfo(userAddress);
        const maxFreeMint = config.freeMint.count;

        const phase = Elemetas.getCurrentPhase();
        if (phase <= Elemetas.Phase.AllowListPhase) {
            if (maxFreeMint > 0 || config.allowList.count > 0) {
                return config.allowList.count + maxFreeMint;
            }
            return 4;
        } else if (phase === Elemetas.Phase.WhiteListPhase) {
            if (maxFreeMint > 0 || config.whiteList.count > 0) {
                return config.whiteList.count + maxFreeMint;
            }
            return 3;
        } else {
            return config.publicSale.count + maxFreeMint;
        }
    }

    // 获取当前阶段已mint数量
    static getMintedCount(userAddress) {
        const phase = Elemetas.getCurrentPhase();
        const config = Elemetas.getUserConfigInfo(userAddress);
        if (phase <= Elemetas.Phase.AllowListPhase) {
            return config.allowList.mintedCount + config.freeMint.mintedCount;
        } else if (phase === Elemetas.Phase.WhiteListPhase) {
            return config.whiteList.mintedCount + config.freeMint.mintedCount;
        } else {
            return config.publicSale.mintedCount + config.freeMint.mintedCount;
        }
    }

    // 用户剩余mint总量
    static getLeftoverTotalMintCount(userAddress) {
        const phase = Elemetas.getCurrentPhase();
        if (phase >= Elemetas.Phase.EndedPhase) {
            return 0;
        }

        let count;
        const config = Elemetas.getUserConfigInfo(userAddress);
        if (phase <= Elemetas.Phase.AllowListPhase) {
            count = Math.max(config.allowList.count - config.allowList.mintedCount, 0);
        } else if (phase === Elemetas.Phase.WhiteListPhase) {
            count = Math.max(config.whiteList.count - config.whiteList.mintedCount, 0);
        } else {
            count = Math.max(config.publicSale.count - config.publicSale.mintedCount, 0);
        }

        count += Elemetas.getLeftoverFreeMintCount(userAddress)
        return count;
    }

    // 用户剩余freeMint数量
    static getLeftoverFreeMintCount(userAddress) {
        const phase = Elemetas.getCurrentPhase();
        if (phase < Elemetas.Phase.EndedPhase) {
            const config = Elemetas.getUserConfigInfo(userAddress);
            return Math.max(0, config.freeMint.count - config.freeMint.mintedCount);
        }
        return 0;
    }

    // 获取用户allowList阶段mint数量
    static getAllowListMintCount(userAddress) {
        return Elemetas.getUserConfigInfo(userAddress).allowList.count;
    }

    // 获取用户whiteList阶段mint数量
    static getWhiteListMintCount(userAddress) {
        return Elemetas.getUserConfigInfo(userAddress).whiteList.count;
    }

    // 获取账户信息：已mint数量及白名单购买数量等信息
    static getUserConfigInfo(userAddress) {
        const user = userAddress ? userAddress.toString().toLowerCase() : nullAddr;
        if (userConfigs.has(user)) {
            return userConfigs.get(user)
        } else {
            return {
                freeMint: {
                    signature: '',
                    count: 0,       // 用户可freeMint数量
                    mintedCount: 0, // 用户freeMint数量
                },
                allowList: {
                    signature: '',
                    count: 0,       // allowList用户可购买数量
                    mintedCount: 0,
                },
                whiteList: {
                    signature: '',
                    count: 0,       // whiteList用户可购买数量
                    mintedCount: 0,
                },
                publicSale: {
                    count: 3,       // 公售阶段可购买数量
                    mintedCount: 0,
                },
                balance: BigNumber.from(0) // 当前地址余额
            }
        }
    }

    // 描述：通过查询合约来更新Configs信息及账户信息
    // 注意：userAddress非必填，不填的话只更新Configs信息，填了的话，同时会更新账户已mint数量
    static async updateConfigsFromContract(userAddress) {
        const user = userAddress ? userAddress.toString().toLowerCase() : nullAddr;
        try {
            const queryContract = await getQueryContract();
            const r = await queryContract.query(user, launchpadAddr, [
                {
                    launchpadId: Elemetas.Configs.allowListPhase.launchpadId,
                    slotId: Elemetas.Configs.allowListPhase.slotId,
                }, {
                    launchpadId: Elemetas.Configs.whiteListPhase.launchpadId,
                    slotId: Elemetas.Configs.whiteListPhase.slotId,
                }, {
                    launchpadId: Elemetas.Configs.publicSalePhase.launchpadId,
                    slotId: Elemetas.Configs.publicSalePhase.slotId,
                }, {
                    launchpadId: Elemetas.Configs.freeMint.launchpadId,
                    slotId: Elemetas.Configs.freeMint.slotId,
                },
            ])

            Elemetas.Configs.totalSupply = r.totalSupply.toNumber();
            Elemetas.Configs.allowListPhase.price = r.slots[0].price;
            Elemetas.Configs.allowListPhase.priceBaseETH = Number(ethers.utils.formatEther(r.slots[0].price));
            Elemetas.Configs.whiteListPhase.price = r.slots[1].price;
            Elemetas.Configs.whiteListPhase.priceBaseETH = Number(ethers.utils.formatEther(r.slots[1].price));
            Elemetas.Configs.publicSalePhase.price = r.slots[2].price;
            Elemetas.Configs.publicSalePhase.priceBaseETH = Number(ethers.utils.formatEther(r.slots[2].price));
            Elemetas.Configs.publicSalePhase.endTime = r.slots[2].saleEnd.toNumber() * 1000;

            if (user !== nullAddr) {
                const userConfig = Elemetas.getUserConfigInfo(user)
                userConfig.balance = r.userBalance;
                userConfig.allowList.mintedCount = r.slots[0].userMinted.toNumber();
                userConfig.whiteList.mintedCount = r.slots[1].userMinted.toNumber();
                userConfig.publicSale.mintedCount = r.slots[2].userMinted.toNumber();
                userConfig.freeMint.mintedCount = r.slots[3].userMinted.toNumber();
                userConfigs.set(user, userConfig);
            }
        } catch (e) {
            console.error(e)
        }
    }

    // 去后台查询并更新某账户的白名单签名以及白名单购买数量等信息
    static async updateWhiteListConfigs(userAddress) {
        const user = userAddress ? userAddress.toString().toLowerCase() : nullAddr;
        if (user === nullAddr) {
            return;
        }
        try {
            const r = await fetch(fetchUrl, {
                method: 'post',
                body: [{
                    address: user,
                    launchpadId: Elemetas.Configs.allowListPhase.launchpadId,
                    launchpadSlotId: Elemetas.Configs.allowListPhase.slotId,
                }, {
                    address: user,
                    launchpadId: Elemetas.Configs.whiteListPhase.launchpadId,
                    launchpadSlotId: Elemetas.Configs.whiteListPhase.slotId,
                }, {
                    address: user,
                    launchpadId: Elemetas.Configs.freeMint.launchpadId,
                    launchpadSlotId: Elemetas.Configs.freeMint.slotId,
                }]
            })
            if (r.code === 0 && r.data?.length === 3) {
                const userConfig = Elemetas.getUserConfigInfo(user)
                if (r.data[0].signature && r.data[0].buyCount != null) {
                    userConfig.allowList.signature = r.data[0].signature;
                    userConfig.allowList.count = Number(r.data[0].buyCount);
                } else {
                    userConfig.allowList.signature = '';
                    userConfig.allowList.count = 0;
                }
                if (r.data[1].signature && r.data[1].buyCount != null) {
                    userConfig.whiteList.signature = r.data[1].signature;
                    userConfig.whiteList.count = Number(r.data[1].buyCount);
                } else {
                    userConfig.whiteList.signature = '';
                    userConfig.whiteList.count = 0;
                }
                if (r.data[2].signature && r.data[2].buyCount != null) {
                    userConfig.freeMint.signature = r.data[2].signature;
                    userConfig.freeMint.count = Number(r.data[2].buyCount);
                } else {
                    userConfig.freeMint.signature = '';
                    userConfig.freeMint.count = 0;
                }
                userConfigs.set(user, userConfig);
            }
        } catch (e) {
            console.error(e)
        }
    }

    // 购买函数。若成功：返回txHash；若失败，则抛出异常
    static async mint(userAddress, mintCount) {
        const user = userAddress ? userAddress.toString().toLowerCase() : nullAddr;
        if (user === nullAddr) {
            throw 'Invalid account: ' + userAddress;
        }
        const signer = await getSigner(user)

        const phase = Elemetas.getCurrentPhase()
        if (phase === Elemetas.Phase.NotStartedPhase) {
            throw 'Mint have not started'
        }
        if (phase === Elemetas.Phase.EndedPhase) {
            throw 'Mint has ended'
        }

        const freeMintCount = Math.min(mintCount, Elemetas.getLeftoverFreeMintCount(userAddress));
        const buyCount = Math.max(mintCount - freeMintCount, 0);

        const parameters = []
        let value = BigNumber.from(0)
        if (freeMintCount > 0) {
            const p = getFreeMintParameter(user, freeMintCount);
            parameters.push(p)
            value = value.add(p.value)
        }
        if (buyCount > 0) {
            const p = getBuyParameter(user, buyCount);
            parameters.push(p)
            value = value.add(p.value)
        }
        if (parameters.length === 0) {
            throw 'Mint error.'
        }
        console.log('p, ', parameters)

        let data = ''
        const launchpad = new ethers.Contract(launchpadAddr, launchpadAbi, signer)
        if (parameters.length === 1) {
            const p = parameters[0]
            const tx = await launchpad.populateTransaction.launchpadBuy(
                '0x00000000', p.launchpadId, p.slotId, p.quantity, p.data === '0x' ? [] : [p.maxWhitelistBuy], p.data, {
                    value
                }
            )
            data = tx.data
        } else {
            const tx = await launchpad.populateTransaction.launchpadBuys(parameters, {value})
            data = tx.data
        }

        const request = {
            from: signer.getAddress(),
            to: launchpadAddr,
            data,
            value
        }

        try {
            const gasLimit = await signer.estimateGas(request)
            request.gasLimit = Math.floor(gasLimit * 1.2)
        } catch (e) {
            if (e && e.toString().includes('execution reverted: 70')) {
                throw `Execution reverted:  This address have exceeded the maximum mint amount`
            } else {
                throw e
            }
        }
        return signer.sendTransaction(request)
    }
}

async function getSigner(user) {
    try {
        if (!window?.ethereum) {
            throw 'Wallet not installed';
        }
    } catch (e) {
        throw 'Wallet not installed';
    }

    if (window.ethereum.enable != null) {
        window.ethereum.enable();
    }

    const cid = await window.ethereum.request({method: 'eth_chainId'})
    if (Number(cid) !== Number(chainId)) {
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId: chainId}]
        })
    }
    return new Web3Provider(window.ethereum).getSigner(user);
}

function getFreeMintParameter(user, mintCount) {
    const userConfig = Elemetas.getUserConfigInfo(user);
    if (!userConfig.freeMint.signature) {
        throw 'Failed to get freeMint signature'
    }
    return {
        launchpadId: Elemetas.Configs.freeMint.launchpadId,
        slotId: Elemetas.Configs.freeMint.slotId,
        quantity: mintCount,
        maxWhitelistBuy: userConfig.freeMint.count,
        data: userConfig.freeMint.signature,
        value: BigNumber.from(0)
    }
}

function getBuyParameter(user, buyCount) {
    const phase = Elemetas.getCurrentPhase()
    if (phase === Elemetas.Phase.AllowListPhase) {
        const userConfig = Elemetas.getUserConfigInfo(user);
        if (!userConfig.allowList.signature) {
            throw 'Failed to get allowList signature'
        }
        return {
            launchpadId: Elemetas.Configs.allowListPhase.launchpadId,
            slotId: Elemetas.Configs.allowListPhase.slotId,
            quantity: buyCount,
            maxWhitelistBuy: userConfig.allowList.count,
            data: userConfig.allowList.signature,
            value: Elemetas.Configs.allowListPhase.price.mul(buyCount)
        }
    }

    if (phase === Elemetas.Phase.WhiteListPhase) {
        const userConfig = Elemetas.getUserConfigInfo(user);
        if (!userConfig.whiteList.signature) {
            throw 'Failed to get whiteList signature'
        }
        return {
            launchpadId: Elemetas.Configs.whiteListPhase.launchpadId,
            slotId: Elemetas.Configs.whiteListPhase.slotId,
            quantity: buyCount,
            maxWhitelistBuy: userConfig.whiteList.count,
            data: userConfig.whiteList.signature,
            value: Elemetas.Configs.whiteListPhase.price.mul(buyCount)
        }
    }

    return {
        launchpadId: Elemetas.Configs.publicSalePhase.launchpadId,
        slotId: Elemetas.Configs.publicSalePhase.slotId,
        quantity: buyCount,
        maxWhitelistBuy: 0,
        data: '0x',
        value: Elemetas.Configs.publicSalePhase.price.mul(buyCount)
    }
}

async function getQueryContract() {
    const isValid = await isWalletValid();
    console.log('isWalletValid-----isValid', isValid)
    if (isValid) {
        return new ethers.Contract(queryAddr, queryAbi, new Web3Provider(window.ethereum))
    } else {
        const rpcUrl = isMainNet ?
            'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161' :
            'https://data-seed-prebsc-1-s1.binance.org:8545'
        return new ethers.Contract(queryAddr, queryAbi, new JsonRpcProvider({
            url: rpcUrl,
            timeout: 15000
        }))
    }
}

async function isWalletValid() {
    try {
        if (!window) {
            return false;
        }
        if (!window.ethereum) {
            return false;
        }
        if (!window.ethereum.isConnected()) {
            return false;
        }
        const id = await window.ethereum.request({method: 'eth_chainId'});
        return Number(id) === Number(chainId)
    } catch (e) {
        return false;
    }
}
