import React , {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import Popup from '../../../components/popup'
import '../styles.scss'
import AboutItem from '../about-item';
// import Button from '../../../components/button';

About.propTypes = {
    data : PropTypes.array,
};

function About(props) {

    const {data} = props;

    const [dataBlock] = useState(
        {
            subtitle : 'Participate',
            title: 'How to get a chance to Mint?',
            desc : "Only Element Pass holders have the initial access to become Element DAO member, get one Element Pass as soon as possible."
        }
    )
    
    const [modalShow, setModalShow] = useState(false);
    useEffect(() => {
        if (modalShow) {
            setTimeout(() => {
                setModalShow(false) 
            }, 3000);
        }
    }, [modalShow])

    return (
        <section className="tf-section tf-about style2 activity-w" id='activity'>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="content-about" data-aos="fade-up" data-aos-duration="800">
                            <div className="tf-title">
                                <p className="h8 sub-title">{dataBlock.subtitle}</p>
                                <h4 className="title">{dataBlock.title}</h4>
                            </div>
                            <p>{dataBlock.desc}</p>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="wrap-about" data-aos="fade-up" data-aos-duration="800">
                            {
                                data.map(item => (
                                    <AboutItem key={item.id} item={item} />
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
            
            <Popup modalShow={modalShow} setModalShow={() => {setModalShow(!modalShow)}} />
        </section>
    );
}

export default About;