import img1 from '../images/post/imgpost11.svg'
import img2 from '../images/post/imgpost13.svg'
import img3 from '../images/post/imgpost12.svg'

const dataBlog = [
    {
        id: 2,
        img : img2,
        category: 'Story',
        title: 'Element Pass Story',
        time: '10 Mar 2022',
        text: 'The nature of the world is fractal.',
        link: '/elemetas-details'
    },
    {
        id: 1,
        img : img1,
        category: 'Story',
        title: 'Why Element DAO?',
        time: '10 Mar 2022',
        text: 'Backed by the Element platform...',
        link: ''
    },
    {
        id: 3,
        img : img3,
        category: 'Story',
        title: "Element Pass journey",
        time: '10 Mar 2022',
        text: 'Who are we?...',
        link: ''
    }
]

export default dataBlog;