export const launchpadAbi = [
  {
    "inputs": [
      {
        "internalType": "bytes4",
        "name": "proxyId",
        "type": "bytes4"
      },
      {
        "internalType": "bytes4",
        "name": "launchpadId",
        "type": "bytes4"
      },
      {
        "internalType": "uint256",
        "name": "slotId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "quantity",
        "type": "uint256"
      }
    ],
    "name": "getAccountInfoInLaunchpad",
    "outputs": [
      {
        "internalType": "bool[]",
        "name": "boolData",
        "type": "bool[]"
      },
      {
        "internalType": "uint256[]",
        "name": "intData",
        "type": "uint256[]"
      },
      {
        "internalType": "bytes[]",
        "name": "byteData",
        "type": "bytes[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "account",
        "type": "address"
      },
      {
        "internalType": "bytes4",
        "name": "proxyId",
        "type": "bytes4"
      },
      {
        "internalType": "bytes4",
        "name": "launchpadId",
        "type": "bytes4"
      },
      {
        "internalType": "uint256",
        "name": "slotId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "quantity",
        "type": "uint256"
      }
    ],
    "name": "getAccountInfoInLaunchpadV2",
    "outputs": [
      {
        "internalType": "bool[]",
        "name": "boolData",
        "type": "bool[]"
      },
      {
        "internalType": "uint256[]",
        "name": "intData",
        "type": "uint256[]"
      },
      {
        "internalType": "bytes[]",
        "name": "byteData",
        "type": "bytes[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "account",
        "type": "address"
      },
      {
        "internalType": "bytes4",
        "name": "launchpadId",
        "type": "bytes4"
      },
      {
        "internalType": "uint256",
        "name": "slotId",
        "type": "uint256"
      }
    ],
    "name": "getAlreadyBuyBty",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes4",
        "name": "",
        "type": "bytes4"
      },
      {
        "internalType": "bytes4",
        "name": "launchpadId",
        "type": "bytes4"
      },
      {
        "internalType": "uint256[]",
        "name": "",
        "type": "uint256[]"
      }
    ],
    "name": "getLaunchpadInfo",
    "outputs": [
      {
        "internalType": "bool[]",
        "name": "boolData",
        "type": "bool[]"
      },
      {
        "internalType": "uint256[]",
        "name": "intData",
        "type": "uint256[]"
      },
      {
        "internalType": "address[]",
        "name": "addressData",
        "type": "address[]"
      },
      {
        "internalType": "bytes[]",
        "name": "bytesData",
        "type": "bytes[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes4",
        "name": "",
        "type": "bytes4"
      },
      {
        "internalType": "bytes4",
        "name": "launchpadId",
        "type": "bytes4"
      },
      {
        "internalType": "uint256",
        "name": "slotId",
        "type": "uint256"
      }
    ],
    "name": "getLaunchpadSlotInfo",
    "outputs": [
      {
        "internalType": "bool[]",
        "name": "boolData",
        "type": "bool[]"
      },
      {
        "internalType": "uint256[]",
        "name": "intData",
        "type": "uint256[]"
      },
      {
        "internalType": "address[]",
        "name": "addressData",
        "type": "address[]"
      },
      {
        "internalType": "bytes4[]",
        "name": "bytesData",
        "type": "bytes4[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "account",
        "type": "address"
      },
      {
        "internalType": "bytes4",
        "name": "launchpadId",
        "type": "bytes4"
      },
      {
        "internalType": "uint256",
        "name": "slot",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "maxBuy",
        "type": "uint256"
      }
    ],
    "name": "hashForWhitelist",
    "outputs": [
      {
        "internalType": "bytes32",
        "name": "",
        "type": "bytes32"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes4",
        "name": "launchpadId",
        "type": "bytes4"
      },
      {
        "internalType": "uint256",
        "name": "slotId",
        "type": "uint256"
      },
      {
        "internalType": "address[]",
        "name": "accounts",
        "type": "address[]"
      },
      {
        "internalType": "uint256[]",
        "name": "offChainMaxBuy",
        "type": "uint256[]"
      },
      {
        "internalType": "bytes[]",
        "name": "offChainSign",
        "type": "bytes[]"
      }
    ],
    "name": "isInWhiteList",
    "outputs": [
      {
        "internalType": "uint8[]",
        "name": "wln",
        "type": "uint8[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes4",
        "name": "",
        "type": "bytes4"
      },
      {
        "internalType": "bytes4",
        "name": "launchpadId",
        "type": "bytes4"
      },
      {
        "internalType": "uint256",
        "name": "slotId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "quantity",
        "type": "uint256"
      },
      {
        "internalType": "uint256[]",
        "name": "additional",
        "type": "uint256[]"
      },
      {
        "internalType": "bytes",
        "name": "data",
        "type": "bytes"
      }
    ],
    "name": "launchpadBuy",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "components": [
          {
            "internalType": "bytes4",
            "name": "launchpadId",
            "type": "bytes4"
          },
          {
            "internalType": "uint256",
            "name": "slotId",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "quantity",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "maxWhitelistBuy",
            "type": "uint256"
          },
          {
            "internalType": "bytes",
            "name": "data",
            "type": "bytes"
          }
        ],
        "internalType": "struct DataType.BuyParameter[]",
        "name": "parameters",
        "type": "tuple[]"
      }
    ],
    "name": "launchpadBuys",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  }
]
