import React from 'react';
import { Link } from 'react-router-dom';

import DotIcon from '../components/dotIcon'


function BlogEleDao(props) {
    return (
        <div className='post-details'>
            <section className="page-title" />
            <section className="tf-section detail">
                <div className="container">
                    <div className="row">
                        <article className="article" data-aos="fade-in" data-aos-duration="800">
                            <div className="post p-b23">
                                <h4 className="title"><Link to="#">Element DAO Member</Link></h4>
                            </div>
                            <div className="content-details mb-41">
                                <p className="st2">1.What's the right of an Element DAO Member?</p>
                                <ul className="list">
                                    <li><DotIcon />
                                        <span> Proposal</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Vote</span>
                                    </li>
                                </ul>
                                <p>2.Each ELE Token holder is an Element DAO Member.</p>
                                <p className="st2">3.How Can You Get ELE Token?</p>
                                <ul className="list">
                                    <li><DotIcon />
                                        <span> Airdrops.</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Trader rewards: including and not limited to trading, listing, and community activities.</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Element Official NFT: Element official NFT holders will receive the equivalent value of token airdrops.</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Secondary market purchases.</span>
                                    </li>
                                </ul>
                            </div>
                        </article>         
                    </div>
                </div>
            </section>
        </div>
        
    );
}

export default BlogEleDao;