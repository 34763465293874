
const dataBanner = [
    // {
    //     id: 1,
    //     title: 'Coming Soon',
    //     classAction: 'three'
    // },
    // {
    //     id: 1,
    //     title: 'EPS start minting today！',
    //     classAction: 'tow'
    // },
    {
        id: 2,
        title: 'Co-Building the Web3 Trading Infrastructure',
        classAction: 'tow'
    }
]

export default dataBanner;