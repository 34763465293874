import React, { useCallback, useEffect, useRef, useState } from 'react';

function Countdown (props) {
    const {date} = props
    const [days, setDays] = useState(0)
    const [hours, setHours] = useState(0)
    const [minutes, setMinutes] = useState(0)
    const [seconds, setSeconds] = useState(0)
    const countdown = useCallback((date) => {
        const nowTime = +new Date()

        const times = (date - nowTime) / 1000
        if(times <= 0) return
        const d = parseInt(times / 60 / 60 / 24)
        const h = parseInt(times / 60 / 60 % 24)
        const m = parseInt(times / 60 % 60)
        const s = parseInt(times % 60)
        setDays(d < 10 ? '0' + d : d)
        setHours(h < 10 ? '0' + h : h)
        setMinutes(m < 10 ? '0' + m : m)
        setSeconds(s < 10 ? '0' + s : s)
    }, [])
    let timer = useRef()
    useEffect(() => {
        countdown(date)
        timer.current = setInterval(() => {
            countdown(date)
        }, 1000)
        return () => {
            if(timer.current) clearInterval(timer.current)
        }
    }, [countdown,date])
    return (
        <div className="featured-countdown">
            <div className="countdown-wrapper">
                <div className="time-section">
                    <div className="time">{days}</div>
                    <small className="time-text">DAY</small>
                    </div>
                <div className="time-section">
                    <div className="time">{hours}</div>
                    <small className="time-text">HRS</small>
                </div>
                <div className="time-section">
                    <div className="time">{minutes}</div>
                    <small className="time-text">MINS</small>
                </div>
                <div className="time-section">
                    <div className="time">{seconds}</div>
                    <small className="time-text">SECS</small>
                </div>
            </div>
        </div>
    )
}

export default Countdown