// const menus = [
//     {
//         id: 1,
//         name: 'Home',
//         links: '/',
//     },
//     {
//         id: 2,
//         name: 'ACTIVITIES🔥',
//         links: '/#activity',
//     },
//     {
//         id: 3,
//         name: 'Join Us',
//         links: '/#joinus',
//     },
//     {
//         id: 4,
//         name: 'About',
//         links: '#about'
//     },
//     {
//         id: 5,
//         name: 'Airdrop🔥',
//         links: '/airdrop'
//     },
//     {
//         id: 6,
//         name: 'Governance',
//         links: 'https://vote.eledao.io',
//     },
//     // {
//     //     id: 6,
//     //     name: 'About',
//     //     links: '#about',
//     //     namesub: [
//     //         {
//     //             id: 1,
//     //             sub: 'Road map',
//     //             links: '#map'
//     //         },
//     //         {
//     //             id: 2,
//     //             sub: 'FAQS',
//     //             links: '#faqs'
//     //         },
//     //         {
//     //             id: 3,
//     //             sub: 'Partners',
//     //             links: '#partners'
//     //         }
//     //     ]
//     // },
//     // {
//     //     id: 7,
//     //     name: 'Tokenomics',
//     //     links: '#tokenomics',
//     //     // namesub: [
//     //     //     {
//     //     //         id: 1,
//     //     //         sub: 'Road Map 01',
//     //     //         links: '/road-map-v1'
//     //     //     },
//     //     //     {
//     //     //         id: 2,
//     //     //         sub: 'Road Map 02',
//     //     //         links: '/road-map-v2'
//     //     //     },
//     //     // ]
//     // },
// ]
const menus = [
    // {
    //     id: 1,
    //     name: 'About',
    //     links: '/#about',
    //     // namesub: [
    //                 // {
    //                 //     id: 1,
    //                 //     sub: 'Element DAO',
    //                 //     links: '/#about'
    //                 // },
    //                 // {
    //                 //     id: 2,
    //                 //     sub: 'How We Work',
    //                 //     links: '/#joinus'
    //                 // },
    //                 // {
    //                 //     id: 3,
    //                 //     sub: 'Team',
    //                 //     links: '/#team'
    //                 // }
    //             // ]
    // },
    {
        id: 3,
        name: 'WEB3 Pass',
        links: '/#benefits',
        // namesub: [
        //     {
        //         id: 3,
        //         sub: 'Mint🔥',
        //         links: '/nft'
        //     },
        //     // {
        //     //     id: 4,
        //     //     sub: 'Apply🔥',
        //     //     links: '/apply',
        //     // },
        //     {
        //         id: 6,
        //         sub: 'Check',
        //         links: '/check',
        //     },
        //     {
        //         id: 1,
        //         sub: 'Overview',
        //         links: '/#feature'
        //     },
        //     {
        //         id: 2,
        //         sub: 'Utilities',
        //         links: '/#benefits'
        //     },
        //     {
        //         id: 5,
        //         sub: 'Story',
        //         links: '/elemetas-details'
        //     },
        // ]
    },
    // {
    //     id: 4,
    //     name: 'Collection',
    //     links: '/#nft',
    //     namesub: [
    //         {
    //             id: 1,
    //             sub: 'Preview',
    //             links: '/nft'
    //         },
    //         {
    //             id: 2,
    //             sub: 'Details',
    //             links: '/details'
    //         }
    //     ]
    // },
    // {
    //     id: 5,
    //     name: 'Roadmap',
    //     links: '/#map'
    // },
    // {
    //     id: 4,
    //     name: 'FAQ',
    //     links: '/#faq'
    // },
    // {
    //     id: 4,
    //     name: 'Distribution',
    //     links: '/distribution'
    // },
    // {
    //     id: 6,
    //     name: 'Element',
    //     links: 'https://www.element.market/'
    // },
    // {
    //     id: 6,
    //     name: 'Governance',
    //     links: 'https://vote.eledao.io',
    // },
    // {
    //     id: 6,
    //     name: 'About',
    //     links: '#about',
    //     namesub: [
    //         {
    //             id: 1,
    //             sub: 'Road map',
    //             links: '#map'
    //         },
    //         {
    //             id: 2,
    //             sub: 'FAQS',
    //             links: '#faqs'
    //         },
    //         {
    //             id: 3,
    //             sub: 'Partners',
    //             links: '#partners'
    //         }
    //     ]
    // },
    // {
    //     id: 7,
    //     name: 'Tokenomics',
    //     links: '#tokenomics',
    //     // namesub: [
    //     //     {
    //     //         id: 1,
    //     //         sub: 'Road Map 01',
    //     //         links: '/road-map-v1'
    //     //     },
    //     //     {
    //     //         id: 2,
    //     //         sub: 'Road Map 02',
    //     //         links: '/road-map-v2'
    //     //     },
    //     // ]
    // },
]

export default menus;