import React, { useState, useCallback, useMemo, useEffect, useRef } from "react";
import Countdown from './countdown'
import Modal from 'react-bootstrap/Modal';
import dayjs from "dayjs";
import _ from 'lodash'
import { toast } from 'react-toastify'
import BN from 'bignumber.js'
import CircleLoading from '../../components/icons/CircleLoading'

import Elemetas from '../../elemetas/elemetas'

const MintDetail = () => {
    const [currentPhase, setCurrentPhase] = useState(0) // 当前阶段
    const getCurrentPhaseTimer = useRef()
    // useEffect(() => {
    //     const getCurrentPhase = () => {
    //         const phase = Elemetas.getCurrentPhase()
    //         setCurrentPhase(phase)
    //         if(phase === Elemetas.Phase.EndedPhase && getCurrentPhaseTimer.current) {
    //             clearInterval(getCurrentPhaseTimer.current)
    //         }
    //     }
    //     getCurrentPhase()
    //     getCurrentPhaseTimer.current = setInterval(() => {
    //         getCurrentPhase()
    //     }, 100);
    //     return () => {
    //         if(getCurrentPhaseTimer.current) clearInterval(getCurrentPhaseTimer.current)
    //     }
    // }, [])

    // const [initLoading, setInitLoading] = useState(false)

    // const [config, setConfig] = useState()
    // const [userConfig, setUserConfig] = useState()
    // const currentPhaseTitle = useMemo(() => ['Guaranteed Allowlist', 'Guaranteed Allowlist', 'Mint Allowlist', 'Public', 'Public'][currentPhase], [currentPhase])
    // const currentPhasePrice = useMemo(() => config && [config.allowListPhase.priceBaseETH, config.allowListPhase.priceBaseETH, config.whiteListPhase.priceBaseETH, config.publicSalePhase.priceBaseETH, config.publicSalePhase.priceBaseETH][currentPhase], [currentPhase,config])
    // const currentPhaseEndTime = useMemo(() => config && [config.allowListPhase.startTime, config.allowListPhase.endTime, config.whiteListPhase.endTime, undefined, undefined][currentPhase], [currentPhase,config])
    // const currentPhaseMaxMintCount = useMemo(() => config && [config.allowListPhase.maxMintCount, config.allowListPhase.maxMintCount, config.whiteListPhase.maxMintCount, config.publicSalePhase.maxMintCount, config.publicSalePhase.maxMintCount][currentPhase], [currentPhase,config])

    // const updateConfigsFromContractTimer = useRef()
    // const updateWhiteListConfigsTimer = useRef()

    // const initFn = useCallback(async () => {
    //     await Promise.all([Elemetas.updateConfigsFromContract(address),Elemetas.updateWhiteListConfigs(address)]).catch(err => console.log(err))
    //     const userConfig = Elemetas.getUserConfig(address)
    //     setUserConfig(_.cloneDeep(userConfig))
    //     setConfig(_.cloneDeep(Elemetas.Configs))
    // }, [address])
    // useEffect(() => {
    //     (async () => {
    //         setInitLoading(true)
    //         await initFn()
    //         setInitLoading(false)
    //     })()
    // }, [initFn])
    // useEffect(() => {
    //     const getUserConfig = () => {
    //         const userConfig = Elemetas.getUserConfig(address)
    //         setUserConfig(userConfig)
    //     }
    //     const updateConfigsFromContract = () => {
    //         Elemetas.updateConfigsFromContract(address).then(() => {
    //             getUserConfig()
    //             setConfig(_.cloneDeep(Elemetas.Configs))
    //         })
    //     }
    //     const updateWhiteListConfigs = () => {
    //         Elemetas.updateWhiteListConfigs(address).then(() => {
    //             getUserConfig()
    //             console.log('updateWhiteListConfigs--------', Elemetas.Configs)
    //             setConfig(_.cloneDeep(Elemetas.Configs))
    //         })
    //     }
    //     updateConfigsFromContractTimer.current = setInterval(() => {
    //         if(dayjs().valueOf() >= (Elemetas.Configs.allowListPhase.startTime - 5 * 60 * 1000)) updateConfigsFromContract()
    //         if(Elemetas.Configs.totalSupply >= Elemetas.Configs.maxSupply || currentPhase === Elemetas.Phase.EndedPhase) {
    //             clearInterval(updateConfigsFromContractTimer.current)
    //         }
    //     }, 5000);
    //     updateWhiteListConfigsTimer.current = setInterval(() => {
    //         updateWhiteListConfigs()
    //         if(Elemetas.Configs.totalSupply >= Elemetas.Configs.maxSupply || currentPhase === Elemetas.Phase.PublicSalePhase || currentPhase === Elemetas.Phase.EndedPhase) {
    //             clearInterval(updateWhiteListConfigsTimer.current)
    //         }
    //     }, 30 * 1000)

    //     return () => {
    //         if(updateConfigsFromContractTimer.current) clearInterval(updateConfigsFromContractTimer.current)
    //         if(updateWhiteListConfigsTimer.current) clearInterval(updateWhiteListConfigsTimer.current)
    //     }
    // }, [address, currentPhase])

    // const currentMaxMintNum = useMemo(() => {
    //     if(!userConfig) return
    //     let max = 2
    //     if(currentPhase === Elemetas.Phase.AllowListPhase) {
    //         max = userConfig.allowList.count - userConfig.userMintedCount
    //     }
    //     if(currentPhase === Elemetas.Phase.WhiteListPhase) {
    //         max = userConfig.whiteList.count - userConfig.userMintedCount
    //     }
    //     if(currentPhase === Elemetas.Phase.PublicSalePhase) {
    //         max = currentPhaseMaxMintCount - userConfig.userMintedCount
    //     }
    //     const currentSupply = config.maxSupply - config.totalSupply
    //     return max < 0 ? 0 : currentSupply > max ? max : currentSupply
    // }, [userConfig, currentPhase, config?.maxSupply, config?.totalSupply, currentPhaseMaxMintCount])
    const [mintNum, setMintNum] = useState(1)
    // const [realMintNum, setRealMintNum] = useState()
    // useEffect(() => {
    //     setMintNum(currentMaxMintNum)
    // }, [currentMaxMintNum])
    // // 输入框 改变
    const onInputChange = useCallback(
        (e) => {
            const value = e.target.value
            if(!value) {
                setMintNum(value)
                return
            }
            if(value < 0) {
                setMintNum(0)
                return
            }
            if(value > 3) {
                setMintNum(3)
                return
            }
            setMintNum(value)
        },
        []
    )
    const onBtnQuantitySubstractClick = useCallback(() => {
        if(mintNum > 0) {
            setMintNum(mintNum - 1)
        }
    }, [mintNum])
    const onBtnQuantityAddClick = useCallback(() => {
        // if(currentMaxMintNum && mintNum > currentMaxMintNum) {
        //     toast.warn(`您已购买${userConfig.userMintedCount}个，最多还能购买${currentMaxMintNum}个`)
        //     setMintNum(currentMaxMintNum)
        //     return
        // }
        if(mintNum < 3) {
            setMintNum(mintNum + 1)
        }
    }, [mintNum])
    const input = useMemo(() => (
        <div className="number-input">
            <span className={`number-input-button ${mintNum <= 0 ? 'disabled' : ''}`} onClick={onBtnQuantitySubstractClick}>-</span>
            <input type='number' 
                value={mintNum} 
                onChange={onInputChange} 
                onBlur={(event) => {
                    const value = event.currentTarget.value
                    if (value === '') {
                        setMintNum('0')
                    }
                }} 
                className="number-input-num" 
            />
            <span className={`number-input-button ${mintNum >= 3 ? 'disabled' : ''}`} onClick={onBtnQuantityAddClick}>+</span>
        </div>
    ), 
    [mintNum, onBtnQuantityAddClick, onBtnQuantitySubstractClick, onInputChange])

    // const [mintLoading, setMintLoading] = useState(false)
    // const [txHash, setTxHash] = useState('')
    // const [modalShow, setModalShow] = useState(false)
    // const [successModalShow, setSuccessModalShow] = useState(false)

    const mintBtnClick = useCallback(() => {
        toast.warn('Sale ended.')
    }, [])
    // const mintBtnClick = useCallback(async () => {
    //     if(initLoading || mintLoading || !config || !userConfig) return
    //     if(currentPhase === 0) {
    //         toast.warn('Sale not start yet.')
    //         return
    //     }
    //     if(currentPhase === 4) {
    //         toast.warn('Sale ended.')
    //         return
    //     }
    //     if(config.totalSupply >= config.maxSupply) {
    //         toast.warn('Sold out.')
    //         return
    //     }
    //     if(mintNum === 0) {
    //         toast.warn('Execution reverted:  This address have exceeded the maximum mint amount.')
    //         return
    //     }
    //     if(currentPhase === 1) {
    //         if(userConfig.allowList.count <= 0 ) {
    //             toast.warn('You’re not eligible for guaranteed allowlist.')
    //             return
    //         }
    //     }
    //     if(currentPhase === 2) {
    //         if(userConfig.whiteList.count <= 0 ) {
    //             toast.warn('You’re not eligible for mint allowlist.')
    //             return
    //         }
    //     }
    //     if(mintNum > currentMaxMintNum) {
    //         toast.warn(`You have purchased ${userConfig.userMintedCount} items, and you can purchase up to ${currentMaxMintNum} items.`)
    //         return
    //     }
    //     if(balance.formatted < currentPhasePrice * mintNum) {
    //         toast.warn('Insufficient balance.')
    //         return
    //     }
    //     if(currentPhase === 3) {
    //     }

    //     setMintLoading(true)

    //     try {
    //         setRealMintNum(mintNum)
    //         const tx = await Elemetas.mint(address, mintNum);
    //         setModalShow(true)
    //         setTxHash(tx?.hash)
    //         console.log('txhash-------', tx?.hash)
    //         await tx.wait()
    //         setSuccessModalShow(true)
    //         setModalShow(false)
    //         await initFn()
    //     } catch(error) {
    //         if(_.isObject(error)) {
    //             if(error.message.includes('user rejected transaction')) {
    //                 toast.error('User rejected transaction')
    //             } else {
    //                 toast.error(error.message)
    //             }
    //         } else {
    //             toast.error(error)
    //         }
    //     }
       
    //     setModalShow(false)
    //     setTxHash('')
    //     setMintLoading(false)
    // }, [initLoading, mintLoading, config, userConfig, currentPhase, mintNum, currentMaxMintNum, balance, currentPhasePrice, address, initFn])

    const elementSvg = useMemo(() => (
        <svg width="22" height="22" viewBox="0 0 130 130" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginRight: '8px'}}>
            <path d="M64.9999 130C100.898 130 130 100.898 130 65C130 29.1015 100.898 0 64.9999 0C29.1014 0 0 29.1015 0 65C0 100.898 29.1014 130 64.9999 130Z" fill="#02BC62"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16 45.9405V61.5326L19.8341 63.6348L41.1911 51.8748V32.3887L16 45.9405ZM17 46.5381L40.1911 34.0622V51.2839L19.8331 62.4937L17 60.9404V46.5381Z" fill="#02BC62"/>
            <path d="M89 52.0001V28L106 37V61L89 52.0001Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M46.6572 48.868L50.9677 46.4943L67.9439 55.803L67.8815 63.2887L72.2153 65.7633L83.4522 59.5808L83.46 28.963L57.7379 15L46.6572 21.0739V48.868Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M50.9666 45.3533L68.9489 55.2136L68.8863 62.7109L72.2244 64.6169L82.4523 58.9895L82.4598 29.5579L57.7402 16.1391L47.6572 21.6661V47.1757L50.9666 45.3533ZM46.6572 48.868V21.0739L57.7379 15L83.46 28.963L83.4522 59.5808L72.2153 65.7633L67.8815 63.2887L67.9439 55.803L50.9677 46.4943L46.6572 48.868Z" fill="#02BC62"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M59.4868 88.365L55.192 86.0378V67.211L62.4151 63.2471L62.4464 58.9884L50.9753 52.6973L22.7388 68.2427V95.9438L34.0693 102.343L59.4868 88.365Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M54.192 86.6333V66.6191L61.4195 62.6528L61.442 59.5781L50.9762 53.8383L23.7388 68.8337V95.3601L34.0758 101.199L57.3998 88.3715L54.192 86.6333ZM59.4868 88.365L34.0693 102.343L22.7388 95.9438V68.2427L50.9753 52.6973L62.4464 58.9884L62.4151 63.2471L55.192 67.211V86.0378L59.4868 88.365Z" fill="#02BC62"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M72.7543 114.589L86.0058 107.328V102.751L65.172 91.4492L47.0166 101.433L72.0047 115L72.0828 114.961L72.7543 114.589ZM72.0037 113.862L72.2738 113.712L85.0058 106.736V103.347L65.1752 92.5887L49.1021 101.427L72.0037 113.862Z" fill="#02BC62"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M88.9181 58.2207V62.7819L72.1605 71.9898L65.1092 67.9715L60.6582 70.4151V82.8111L88.7854 98.0696L88.5511 98.4962L114 84.5487V71.9975L88.9181 58.2207Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M114 84.5487V71.9975L88.9181 58.2207V62.7819L72.1605 71.9898L65.1092 67.9715L60.6582 70.4151V82.8111L88.7854 98.0696L88.5511 98.4962L114 84.5487ZM89.0565 97.0789L113 83.9564V72.5892L89.9181 59.9109V63.3735L72.1516 73.1357L65.1001 69.1173L61.6582 71.0069V82.2159L89.0565 97.0789Z" fill="#02BC62"/>
            <path d="M20 61.5V45L40 34V51L20 61.5Z" fill="white"/>
            <path d="M48 102L64 93L85 104L69 113L48 102Z" fill="white"/>
        </svg>
    ), [])

    // useEffect(() => {
    //     if(successModalShow) {
    //         for(let i=0; i<100; i++) {
    //             // Random rotation
    //             var randomRotation = Math.floor(Math.random() * 360);
    //             // Random width & height between 0 and viewport
    //             var randomWidth = Math.floor(Math.random() * Math.max(document.documentElement.clientWidth, window.innerWidth || 0));
    //             var randomHeight =  Math.floor(Math.random() * Math.max(document.documentElement.clientHeight, window.innerHeight || 0));
                
    //             // Random animation-delay
    //             var randomAnimationDelay = Math.floor(Math.random() * 2);
              
    //             // Random colors
    //             var colors = ['#0CD977', '#FF1C1C', '#FF93DE', '#5767ED', '#FFC61C', '#8497B0']
    //             var randomColor = colors[Math.floor(Math.random() * colors.length)];
              
    //             // Create confetti piece
    //             var confetti = document.createElement('div');
    //             confetti.className = 'confetti';
    //             confetti.style.top=randomHeight + 'px';
    //             confetti.style.left=randomWidth + 'px';
    //             confetti.style.backgroundColor=randomColor;
    //             confetti.style.transform='skew(15deg) rotate(' + randomRotation + 'deg)';
    //             confetti.style.animationDelay=randomAnimationDelay + 's';
    //             document.querySelectorAll(".check-allowList-modal")[0]?.appendChild(confetti);
    //           }
    //     }
    // }, [successModalShow])

    return (
        <div className="mint-detail">
            <div className="quantity-bar m-b20">
                <div className="quantity-bar-top">
                    <div>Total Items</div>
                    <div><b>{Elemetas.Configs.maxSupply}</b> / {Elemetas.Configs.maxSupply}</div>
                </div>
                <div className="quantity-bar-box">
                    <div className="quantity-bar-active" style={{width: `${Elemetas.Configs.maxSupply / Elemetas.Configs.maxSupply * 100}%`}} />
                </div>
            </div>
            <div className='phase-box'>
                <h6 className="heading">Mint Allowlist</h6>
                <div className="current-phase">
                    <div className="current-phase-price">
                        <span>Price</span> <h6>0.045 ETH</h6>
                    </div>
                    {/* {!initLoading && currentPhaseEndTime && <Countdown date={currentPhaseEndTime} />} */}
                </div>
                <div className="split-line" />
                <div className="price-box">
                    <div className="price-box-input">
                        {input}
                        <span className="max-mint">Max 3</span>
                    </div>
                    <div className="price-box-count">
                        <div className="total-price"><span>Total</span> <div className="total-num">{mintNum === '' ? '-' : Number(new BN(mintNum).multipliedBy(0.045))} ETH</div></div>
                    </div>
                </div>
            </div>
            <div className="mint-btn-box">
                <div className={`mint-btn tf-button btn-effect sold-out`} onClick={mintBtnClick}>
                    <span className="boder-fade"></span>
                    <span className="effect">{'Sold out'}</span>
                </div>
                <a className="mint-btn tf-button btn-effect view" href="https://element.market/collections/element-pass-standard" target={'_blank'} rel="noreferrer">
                    <span className="boder-fade"></span>
                    <span className="effect">{elementSvg}View Collection</span>
                </a>
            </div>

            {/* <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="lg"
                centered
                className='check-allowList-modal'
            >
                <div className='check-allowList-modal-bg' />
                <Modal.Header closeButton closeVariant='white'>
                    <div className="h6">Mint</div>
                </Modal.Header>
                <Modal.Body>
                    
                    <div className='check-allowList-modal-content'>
                        <div className='check-allowList-modal-content-bg' />
                        <p className='check-allowList-modal-content-text'>
                            Element Pass Genesis x{realMintNum}<br />Waiting for the transaction to complete...
                            <a className='tx-await-modal-text' href={`https://etherscan.io/tx/${txHash}`} target="_blank" rel="noreferrer">
                                <CircleLoading /> View on Etherscan
                            </a>
                        </p>
                        <div className='check-allowList-modal-content-bg2' />
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                show={successModalShow}
                onHide={() => setSuccessModalShow(false)}
                size="lg"
                centered
                className='check-allowList-modal'
            >
                <div className='check-allowList-modal-bg' />
                <Modal.Header closeButton closeVariant='white'>
                    <div className="h6">Mint</div>
                </Modal.Header>
                <Modal.Body>
                    <div className='check-allowList-modal-content'>
                        <div className='check-allowList-modal-content-bg' />
                        <p className='check-allowList-modal-content-text'>
                            Congratulations!<br />Element Pass Genesis mint x{realMintNum} success.
                        </p>
                        <div className='check-allowList-modal-content-bg2' />
                        <a className="tf-button btn-effect" href={`https://www.element.market/account`} target="_blank" rel="noreferrer">
                            <span className="boder-fade"></span>
                            <span className="effect" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                {elementSvg}
                                View On Element
                            </span>
                        </a>
                    </div>
                </Modal.Body>
            </Modal> */}
        </div>
    )
}

export default MintDetail