const dataWork = [
    {
        id: 1,
        numb: '01',
        title: 'Become Element DAO Member',
        text : 'Join Element Discord #Element DAO channel and get your special identity as an Element DAO member!',
        // active: 'active',
        link: '/eledao-member'
    },
    {
        id: 2,
        numb: '02',
        title: 'Become Verified Creator',
        text: 'Element verified creator could gain promotion resources from the Element platform',
        link: '/eledao-verified-creator'
    },
    {
        id: 3,
        numb: '03',
        title: "Become Volunteer",
        text: 'Moderators, Collectors, blue-chip asset holders and traders, Who are interested in contributing to Element DAO.',
        link: '/eledao-volunteer'
    },
    {
        id: 4,
        numb: '04',
        title: "Become the first Proposer",
        text: 'The proposers are the first batch of people who have the privilege to initiate a proposal and let Element DAO members vote.',
        link: '/eledao-proposer'
    }
]
export default dataWork;