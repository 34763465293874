import React from 'react';
import { Link } from 'react-router-dom';

import img from '../assets/images/post/elemetas.svg'


function Distribution(props) {
    return (
        <div className='distribution'>
            <section className="tf-section">
                <div className="container">
                    <div className="col-md-12">
                        <div className="content-about mobie-40 mb-60" data-aos="fade-in" data-aos-duration="800">
                            <div className="tf-title">
                                <h4 className="title">ELE Distribution</h4>
                            </div>
                            <p className='center pd10'>ELE is the DAO governance token and will primarily be allocated to users and builders of the DAO ecosystem.</p>
                        </div>
                    </div>
                    <div className="submit" data-aos="fade-in" data-aos-duration="800">
                        <div className='h8'>ELE TOTAL</div>
                        <div className='h8'>2,000,000,000</div>
                    </div>
                    <div className='table' data-aos="fade-in" data-aos-duration="800">
                        <div className='tr theader'>
                            <div className='th'>Event</div>
                            <div className='th'>Released</div>
                            <div className='th'>Percent %</div>
                        </div>
                        <div className='tr'>
                            <div className='td'>OG User Airdrop</div>
                            <div className='td'>22,000,000</div>
                            <div className='td'>1.10%</div>
                        </div>
                        <div className='tr'>
                            <div className='td'>Elemental Storm Event (Season 1)</div>
                            <div className='td'>25,000,000</div>
                            <div className='td'>1.25%</div>
                        </div>
                        <div className='tr'>
                            <div className='td'>Element Pass Genesis (Staking Phase 1)</div>
                            <div className='td'>25,000,000</div>
                            <div className='td'>1.25%</div>
                        </div>
                        <div className='tr'>
                            <div className='td'>Element Pass Standard (Staking Phase 1)</div>
                            <div className='td'>36,000,000</div>
                            <div className='td'>1.8%</div>
                        </div>
                        <div className='tr'>
                            <div className='td'>Element Pass Genesis (Staking Phase 2)</div>
                            <div className='td'>10,950,000</div>
                            <div className='td'>0.55%</div>
                        </div>
                        {/* <div className='tr'>
                            <div className='td'>Element Pass Standard (Staking Phase 2)</div>
                            <div className='td'>16,200,000</div>
                            <div className='td'>0.81%</div>
                        </div> */}
                        <div className='tr'>
                            <div className='td'>...</div>
                            <div className='td'>...</div>
                            <div className='td'>...</div>
                        </div>
                        <div className='tr'>
                            <div className='td'></div>
                            <div className='td'><b>118,950,000</b></div>
                            <div className='td'><b>5.95%</b></div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        
    );
}

export default Distribution;