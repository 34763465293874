import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

WorkItem.propTypes = {
  item: PropTypes.object,  
};

function WorkItem(props) {

    const {item} = props;

    return (
        <a href={item.link} className={`box-text st2 corner-box ${item.active}`} target='_blank' rel="noreferrer">
            <div className="number">{item.numb}</div>
            <div className="h7">{item.title}</div>
            <p>{item.text}</p>
        </a>
    );
}

export default WorkItem;