import React from 'react';
import PropTypes from 'prop-types';
import { Navigation, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

import SliderItem from '../slider-item';
import BannerBg from './bannerBg';

Slider.propTypes = {
    data : PropTypes.array,
};

function Slider(props) {
    const {data} = props;
    return (
        <section className="slider" id="banner-btn" style={{ background: '#000', height: '100vh' }}>
            <BannerBg />
            <Swiper
                modules={[Navigation, Scrollbar, A11y, Autoplay ]}
                spaceBetween={0}
                slidesPerView={1}
                // navigation
                scrollbar={{ draggable: true }}
                // autoplay={{
                //     delay: 8000,
                //     disableOnInteraction: false
                // }}
                // loop
            >
                {
                    data.map(item => (
                        <SwiperSlide key={item.id}>
                            <SliderItem item={item} />
                        </SwiperSlide>
                        
                    ))
                }
                {/* <SwiperSlide>
                    <SliderItem item={data[0]} />
                </SwiperSlide> */}
            </Swiper>

        </section>
    );
}

export default Slider;