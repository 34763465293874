import fetch from './fetch.js'

export const postTradeVolume = async (params) => {
    const res = await fetch('/mapi/qualified/trade/volume', {
        method: 'post',
        body: params
    })

    return res
}

export const postElementPassSignUp = async (params) => {
    const res = await fetch('/mapi/qualified/elementPass/signUp', {
        method: 'post',
        body: params
    })

    return res
}