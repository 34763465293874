import React from 'react';
import PropTypes from 'prop-types';

import {  A11y   } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

import './styles.scss'

Partner.propTypes = {
    data: PropTypes.array,
    eType: PropTypes.string
};

function Partner(props) {

    const {data, eType} = props;

    return (
        <section className="tf-section partner" id="partners">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="tf-title" data-aos="fade-up" data-aos-duration="800">
                            <h4 className="title">{eType === 'element' ? 'TECH PARTNER' : 'ECO PARTNERS' }</h4>
                        </div>
                    </div>
                    {eType === 'element' ? 
                    <div className="col-md-12">
                        <div className="line-list center" data-aos="fade-up" data-aos-duration="800">
                            <a className="image" href='https://element.market/' target='_blank' rel="noreferrer">
                                <img className="element" src={data[0].img} alt="" />
                            </a>
                        </div>
                        </div> : <>
                    <div className="col-md-12">
                        <div className="line-list" data-aos="fade-up" data-aos-duration="800">     
                            {
                                data.slice(1).map((item,idx) => (
                                    <div className="image" key={idx}>
                                        <img src={item.img} alt="" />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    </>}
                </div>
            </div>
        </section>
    );
}

export default Partner;