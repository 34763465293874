import React from 'react';

import '../scss/components/box.scss';
import '../scss/components/section.scss';
import Banner from '../components/slider/banner';
import dataBanner from '../assets/fake-data/data-banner';
import dataFeature from '../assets/fake-data/data-feature';
import dataBenefits from '../assets/fake-data/data-benefits';
import dataBenefitsStandard from '../assets/fake-data/data-benefits-standard';
import About from '../features/about';
import dataAbout from '../assets/fake-data/data-about';
import RoadMap from '../features/roadmap/home-v3';
import dataRoadmap from '../assets/fake-data/data-roadmap';
import Activity from '../features/activity/home-v2'
import dataActivity from '../assets/fake-data/data-activity';
import Partner from '../features/partner';
import dataPartner from '../assets/fake-data/data-partner';
import FAQ from '../features/faq/home-v3';
import dataFaq from '../assets/fake-data/data-faq';
import AboutV3 from '../features/about/home-v3';
import AboutV4 from '../features/about/home-v4';
import Bgm from '../components/bgm'
import Blog from '../features/blog';
import dataBlog from '../assets/fake-data/data-blog';
import Ecosystem from '../features/ecosystem'
import dataEcosystem from '../assets/fake-data/data-ecosystem';

function HomeThree(props) {
    return (
        <div className='home-2'>
            <Banner data={dataBanner} />

            <About data={dataAbout} />

            {/* <JoinUs data={dataWork} /> */}

            {/* <Team data={dataTeam} /> */}

            {/* <AboutV3 data={dataFeature} /> */}

            <AboutV4 data={dataBenefits} dataStandard={dataBenefitsStandard} />

            {/* <Activity data={dataActivity} /> */}

            {/* <Community /> */}

            {/* <RoadMap data={dataRoadmap} /> */}

            {/* <Blog data={dataBlog} /> */}

            {/* <FAQ data={dataFaq} /> */}
            
            <Ecosystem data={dataEcosystem} />

            <Partner data={dataPartner} eType="element" />

            <Partner data={dataPartner} eType="order" />

            {/* <Bgm /> */}
        </div>
    );
}

export default HomeThree;