export const queryAbi = [
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "account",
        "type": "address"
      },
      {
        "internalType": "contract ILaunchpad",
        "name": "launchpad",
        "type": "address"
      },
      {
        "components": [
          {
            "internalType": "bytes4",
            "name": "launchpadId",
            "type": "bytes4"
          },
          {
            "internalType": "uint256",
            "name": "slotId",
            "type": "uint256"
          }
        ],
        "internalType": "struct ElementPassStandardHelper.SlotId[]",
        "name": "ids",
        "type": "tuple[]"
      }
    ],
    "name": "query",
    "outputs": [
      {
        "components": [
          {
            "internalType": "uint256",
            "name": "userBalance",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "userTotalMinted",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "totalSupply",
            "type": "uint256"
          },
          {
            "internalType": "address",
            "name": "targetContract",
            "type": "address"
          },
          {
            "components": [
              {
                "internalType": "bool",
                "name": "enable",
                "type": "bool"
              },
              {
                "internalType": "uint256",
                "name": "whiteListModel",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "price",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "saleStart",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "saleEnd",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "userMinted",
                "type": "uint256"
              }
            ],
            "internalType": "struct ElementPassStandardHelper.Slot[]",
            "name": "slots",
            "type": "tuple[]"
          }
        ],
        "internalType": "struct ElementPassStandardHelper.Returns",
        "name": "r",
        "type": "tuple"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  }
]
