const dataRoadmap = [
    {
        id: 1,
        time: '2024 Q1',
        list: [
            {
                text: 'Launchpad 2.0 (ERC404/Drops);'
            },
            {
                text: 'ProTrade 2.0;'
            },
        ],
        positon: 'left'
    },
    {
        id: 2,
        time: '2024 Q2',
        list: [
            {
                text: 'NFTaaS 1.0;'
            },
            {
                text: 'NFT AMM;'
            }
        ],
        positon: 'left'
    },
    {
        id: 3,
        time: '2024 Q3',
        list: [
            {
                text: "Web3 APP;"
            },
            {
                text: "Cross Chain NFT Bridge & Swap;"
            }
        ],
        positon: 'left'
    },
    {
        id: 4,
        time: '2024 Q4',
        list: [
            {
                text: "NFTaaS 2.0;"
            },
        ],
        positon: 'left'
    }
]

export default dataRoadmap;