import React , {useState} from 'react';
import PropTypes from 'prop-types';
import '../styles.scss'
import AboutItem from '../about-item';
import Button from '../../../components/button';

About.propTypes = {
    data: PropTypes.array,
    dataStandard: PropTypes.array,
};

function About(props) {

    const {data, dataStandard} = props;
    const [tab, setTab] = useState(1)

    const [dataBlock] = useState(
        {
            subtitle : 'ELEMENT PASS Utilities',
            title: 'FULL RIGHTS AND BENEFITS FOR HOLDERS',
            desc : "Element Pass Genesis (<a href='https://element.market/collections/element-pass-genesis' target='_blank' rel='noreferrer' style='color: #00DA70;'>EPG</a>) and Element Pass Standard (<a href='https://element.market/collections/element-pass-standard' target='_blank' rel='noreferrer' style='color: #00DA70;'>EPS</a>) are NFT collections for DAO members, partners and users.",
            // descStandard: "Element Pass Genesis (<a href='https://element.market/collections/element-pass-genesis' target='_blank' rel='noreferrer' style='color: #00DA70;'>EPG</a>) and Element Pass Standard (<a href='https://element.market/collections/element-pass-standard' target='_blank' rel='noreferrer' style='color: #00DA70;'>EPS</a>) are NFT collections for DAO members, partners and users."
        }
    )

    return (
        <section className="tf-section tf-about" id='benefits'>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="content-about mobie-40 mb-60" data-aos="fade-up" data-aos-duration="800">
                            <div className="tf-title">
                                <h4 className="title">{dataBlock.subtitle}</h4>
                                {/* <h4 className="title">
                                    <div className={`title-tab ${tab === 2 ? 'active' : ''}`} onClick={() => setTab(2)}>Standard HOLDERS</div>
                                    <div className={`title-tab ${tab === 1 ? 'active' : ''}`} onClick={() => setTab(1)}>Genesis HOLDERS</div>
                                </h4> */}
                            </div>
                            <p className='center' dangerouslySetInnerHTML={{__html: tab === 1 ? dataBlock.desc : dataBlock.descStandard}}></p>
                        </div>
                    </div>

                        {
                            (tab === 1 ? data : dataStandard).map(item => (
                                <div key={item.id} className="col-xl-4 col-lg-4 col-md-12" data-aos="fade-up" data-aos-delay="100" data-aos-duration="800">
                                    <div key={item.id} className={`box-text corner-box utilities-box`}>
                                        <img className='image' src={item.img} alt='' />
                                        <div className="h7">{item.title}</div>
                                        <p>{item.desc}</p>
                                    </div>
                                </div>
                            ))
                        }
                    {/* <div className="col-md-12">
                        <div className="btn-about center" data-aos="fade-up" data-aos-duration="800">
                            <Button title='get Nfts' path='/nft' />
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    );
}

export default About;