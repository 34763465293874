import React from 'react';
import { Link } from 'react-router-dom';

import DotIcon from '../components/dotIcon'


function BlogEleDao(props) {
    return (
        <div className='post-details'>
            <section className="page-title" />
            <section className="tf-section detail">
                <div className="container">
                    <div className="row">
                        <article className="article" data-aos="fade-in" data-aos-duration="800">
                            <div className="post p-b23">
                                <h4 className="title"><Link to="#">Verified Creator</Link></h4>
                            </div>
                            <div className="content-details mb-41">
                                <p className="st2">1.Element verified creator could gain promotion resources from the Element platform, at least including:</p>
                                <ul className="list">
                                    <li><DotIcon />
                                        <span> Collection Verified;</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Banner Promotion;</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Trading&Listing rewards (possible);</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Social Media promotion;</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> TwitterSpace Invitation;</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Qualified analysis article;</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Collection Invitation Rebate Link;</span>
                                    </li>
                                </ul>
                                <p className="st2">2.How to apply a verified creator?</p>
                                <p>Application Link:
                                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSe2pJZ3i8SgAuEqaynp9CmZ6HXG-_51LK-D6ImDK4XvSYcuug/viewform?usp=sf_link" target='_blank' rel="noreferrer">https://docs.google.com/forms/d/e/1FAIpQLSe2pJZ3i8SgAuEqaynp9CmZ6HXG-_51LK-D6ImDK4XvSYcuug/viewform?usp=sf_link</a>
                                </p>
                            </div>
                        </article>
                    </div>
                </div>
            </section>
        </div>
        
    );
}

export default BlogEleDao;