import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';


Ecosystem.propTypes = {
    data: PropTypes.array,
};

function Ecosystem(props) {

    const {data} = props;

    const [dataBlock] = useState({
        subtitle: '',
        title: 'DAO MEMBERS'
    })

    return (
        <section className="tf-section ecosystem bg-st1" id="faq">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="tf-title mb-58" data-aos="fade-up" data-aos-duration="800">
                            {/* <p className="h8 sub-title">{dataBlock.subtitle}</p> */}
                            <h4 className="title">{dataBlock.title}</h4>
                        </div>
                    </div>
                    <div data-aos="fade-up" data-aos-duration="800">
                        <div className="ecosystem-accordion">
                        {
                            Array.from({length: Math.ceil(data.length / 15)}).map((_,i) => <div className="ecosystem-accordion-content">
                                {
                                    Array.from({length: 2}).map((_) => data.slice(i * 17, (i + 1) * 17).map(item => (
                                        <a className='image-item' key={item.id} href={item.link} target='_blank' rel="noreferrer">
                                            <img className='image' src={item.image} alt='' />
                                            {/* <div className='text-box'>EPG #190</div> */}
                                        </a>
                                    )))
                                }
                            </div>)
                        }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Ecosystem;