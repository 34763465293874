import React from 'react';
import PropTypes from 'prop-types';

AboutItem.propTypes = {
    item: PropTypes.object,
};

function AboutItem(props) {

    const {item, setModalShow} = props;

    // return (
    //     <a href={item.link} target='_blank'  key={item.id} className={`box-text corner-box ${item.class}`} rel="noreferrer">
    //         <div className="h7">{item.title}</div>
    //         <p>{item.desc}</p>
    //     </a>
    // );
    return (item.id === 3)
        ? 
        <div key={item.id} className={`box-text corner-box ${item.class}`} style={{cursor: 'pointer'}}
            onClick={() => {
                if(setModalShow) setModalShow(true)
            }}>
                <div className="h7">{item.title}</div>
                {item.desc && <p>{item.desc}</p>}
                {item.list && <ul>
                {
                    item.list.map((li, idx) => (
                        <li key={idx}>{li.text}</li>
                    ))
                }
            </ul>}
            </div>
        : 
            <a href={item.link} target='_self'  key={item.id} className={`box-text corner-box ${item.class}`} rel="noreferrer">
                <div className="h7">{item.title}</div>
                {item.desc && <p>{item.desc}</p>}
                {item.list && <ul>
                {
                    item.list.map((li, idx) => (
                        <li key={idx} dangerouslySetInnerHTML={{__html: li.text}}></li>
                    ))
                }
                </ul>}
            </a>
    
}

export default AboutItem;