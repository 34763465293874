import React , { useState , useEffect } from 'react';

import { NavLink } from 'react-router-dom';
import menus from '../../pages/menu';

import './styles.scss';
import logo from '../../assets/images/logo/logo-new.svg'
import Popup from '../popup';

import { useConnectWallet } from '@web3-onboard/react'

const Header = () => {
    const [{ wallet, connecting }, connect] = useConnectWallet()

    console.log(wallet)

    const [scroll, setScroll] = useState(false);
        useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 108);
        });
        return () => {
            setScroll({});
        }
    }, []);

    const [menuActive, setMenuActive] = useState(null);

    const handleMenuActive = () => {
        setMenuActive(!menuActive);
      };

    
    const [activeIndex, setActiveIndex] = useState(null);
    const handleDropdown = index => {
        setActiveIndex(index); 
    };
    
    const [modalShow, setModalShow] = useState(false);
    useEffect(() => {
        if (modalShow) {
            setTimeout(() => {
                setModalShow(false) 
            }, 3000);
        }
    }, [modalShow])

    return (
        <header id="header_main" className={`header ${scroll ? 'is-fixed' : ''}`}>
            <div className="container">
                <div id="site-header-inner">
                    <div className="header__logo">
                        <NavLink to="/"><img src={logo} style={{height: '40px'}} alt="Element DAO" /></NavLink>
                    </div>
                    <nav id="main-nav" className={`main-nav ${menuActive ? 'active' : ''} ${wallet ? 'has-wallet' : ''}`} >
                        <ul id="menu-primary-menu" className="menu">

                            {
                                menus.map((data,idx) => (
                                    <li key={idx} onClick={()=> handleDropdown(idx)} className={`menu-item ${data.namesub ? 'menu-item-has-children' : ''} ${activeIndex === idx ? 'active' : ''}`}>
                                        <a href={data.links}>{data.name}</a>
                                        {
                                            data.namesub &&
                                            <ul className="sub-menu">
                                                {
                                                    data.namesub.map((submenu) => (
                                                        <li key={submenu.id} className="menu-item">
                                                            <a href={submenu.links} rel="noreferrer">{submenu.sub}</a>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        }
                                        
                                    </li>
                                ))
                            }
                        </ul>
                    </nav>
                    {/* <Button title='Join Discord' path='#' onClick={()=>{setModalShow(true)}} /> */}
                    
                    {/* <a className="tf-button btn-effect" href='https://discord.com/invite/elementmarket' target="_blank" rel="noreferrer">   
                        <span className="boder-fade"></span>                                     
                        <span className="effect">Join Discord</span>
                    </a> */}
                    {!wallet && <div className='nav-wallet-btn'>
                        <button className="tf-button btn-effect" disabled={connecting} onClick={() => connect()}>
                            <span className='boder-fade'></span>
                            <span className='effect'>connect</span>
                        </button>
                    </div>}
                    

                    <div className={`mobile-button ${menuActive ? 'active' : ''}`} onClick={handleMenuActive}><span></span></div>
                </div>
            </div>
            
            <Popup modalShow={modalShow} setModalShow={() => {setModalShow(!modalShow)}} />
        </header>
    );
}

export default Header;