import img1 from '../images/background/benefits-1.svg'
import img2 from '../images/background/benefits-2.svg'
import img3 from '../images/background/benefits-3.svg'
import img4 from '../images/background/benefits-4.svg'
import img5 from '../images/background/benefits-5.svg'
import img6 from '../images/background/benefits-6.svg'
import img7 from '../images/background/benefits-7.svg'
import img8 from '../images/background/benefits-8.svg'
import img9 from '../images/background/benefits-9.svg'

const dataFeature = [
    {
        id: 1,
        title: 'Identity',
        desc: "Element DAO Member",
        img: img1
    },
    {
        id: 3,
        title: 'Pro Features',
        desc: "Data Analytics, Pro Trading",
        img: img3
    },
    {
        id: 2,
        title: 'Staking',
        desc: "Staking pass to recieve $ELE tokens",
        img: img2
    },
    {
        id: 4,
        title: 'Growth Pass',
        desc: "Improve user loyalty and receive eco rewards",
        img: img4
    },
    {
        id: 5,
        title: 'Senior User',
        desc: "Enjoy the same rights as VIP user",
        img: img5
    },
    {
        id: 6,
        title: 'Eco Airdrop',
        desc: "WLs, NFTs, and tokens from ecosystem partners",
        img: img6
    },
    {
        id: 7,
        title: 'Launchpad',
        desc: "Opportunity for free participation, priority access",
        img: img7
    },
    {
        id: 8,
        title: '?',
        desc: "...",
        img: img8
    },
    {
        id: 9,
        title: '?',
        desc: "...",
        img: img9
    },
]

export default dataFeature;