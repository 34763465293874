import React from 'react';
import PropTypes from 'prop-types';
import { Accordion } from 'react-bootstrap-accordion';

AccordionItem.propTypes = {
    item: PropTypes.object,
};

function AccordionItem(props) {
    const {item} = props;

    return (
        <Accordion key={item.id} title={item.title} show={item.show}>
            {item.content && <p className="toggle-content">{item.content}</p>}
            {item.list && <ul>
                {
                    item.list.map((li, idx) => (
                        <li key={idx}>{li.text}</li>
                    ))
                }
            </ul>}
        </Accordion>
    );
}

export default AccordionItem;