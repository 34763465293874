import React from 'react';
import { Link } from 'react-router-dom';

import DotIcon from '../components/dotIcon'


function BlogEleDao(props) {
    return (
        <div className='post-details'>
            <section className="page-title">
            </section>
            <section className="tf-section detail">
                <div className="container">
                    <div className="row">
                        <article className="article" data-aos="fade-in" data-aos-duration="800">
                            <div className="post p-b23">
                                <h4 className="title"><Link to="#">Why Element DAO?</Link></h4>
                                <ul className="meta-post">
                                    <li><span>by</span>Element DAO</li>
                                    <li>1 Sep 2022</li>
                                </ul>  
                            </div>
                            <div className="content-details">
                                <h6>Definition</h6>
                                <p>Backed by the Element platform, Element DAO unites traders, creators, developers, investors, and many communities to advocate co-construction,
                                    sharing, and co-ownership, and is committed to creating a Web3 encrypted economic community,
                                    making NFTs as new elements to link between the physical world and the encrypted world.
                                </p>
                                <div className="image">
                                    <img src={require('../assets/images/post/imgpost1.jpeg')} alt="" />
                                </div>
                            </div>
                            <div className="content-details">
                                <h6>Functions</h6>
                                <ul className="list">
                                    <li><DotIcon />
                                        <span> Element DAO will launch the Element platform economic model, and ELE tokens will serve as the platform's utility token;</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Element DAO will actively reward traders and trading behavior related;</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Element DAO will empower NFT creators, especially CC0 creators, to empower the creation, distribution, and operation of NFT;</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Element DAO will work to improve the NFT ecology and promote the deep linkage of NFT in WEB2 and WEB3 worlds: on the one hand, to promote NFT empowered brands, and on the other hand, to promote the prosperity of crypto-entertainment worlds such as NFT, GameFi and meta-universe.</span>
                                    </li>
                                </ul>
                            </div>
                            <h6>Roles</h6>
                            <div className="content-details">
                                <h6>A. Element DAO Member</h6>
                                <p className="st2">1.What's the right of an Element DAO Member?</p>
                                <ul className="list">
                                    <li><DotIcon />
                                        <span> Proposal</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Vote</span>
                                    </li>
                                </ul>
                                <p>2.Each ELE Token holder is an Element DAO Member.</p>
                                <p className="st2">3.How Can You Get ELE Token?</p>
                                <ul className="list">
                                    <li><DotIcon />
                                        <span> Airdrops.</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Trader rewards: including and not limited to trading, listing, and community activities.</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Element Official NFT: Element official NFT holders will receive the equivalent value of token airdrops.</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Secondary market purchases.</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="content-details">
                                <h6>B. Element DAO Proposer</h6>
                                <p>1.The proposers are the first batch of people who have the privilege to initiate a proposal and let Element DAO members vote.</p>
                                <p className="st2">2.Who Can Become the First Proposer?</p>
                                <ul className="list">
                                    <li><DotIcon />
                                        <span> Founder and core members of projects within the NFT ecosystem.</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Well-known public chains, wallets, and infrastructures in the Blockchain space.</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> KOLs with creative abilities in social media such as Twitter, Medium, Notion, Reddit, etc.</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Well-known NFT creators.</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Well-known NFT collectors.</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Well-known blue-chip asset holders.</span>
                                    </li>
                                </ul>
                                <p>Note: All Element DAO leaders will be selected from among the Element Official NFT holders.</p>
                                <p className="st2">3.How to apply the first proposer?</p>
                                <p>Application Link:<a href="https://docs.google.com/forms/d/e/1FAIpQLSf03o7BjeuIP9fpl2XpIRJlKnCZqgh1WTEV8DGeAuB8e0Uxcw/viewform?usp=sf_link" target='_blank' rel="noreferrer">
                                        https://docs.google.com/forms/d/e/1FAIpQLSf03o7BjeuIP9fpl2XpIRJlKnCZqgh1WTEV8DGeAuB8e0Uxcw/viewform?usp=sf_link</a>
                                </p>
                                <p>4.All Element DAO proposers will be selected from among the Element Official NFT holders.</p>
                            </div>
                            <div className="content-details">
                                <h6>C. Verified Creator</h6>
                                <p className="st2">1.Element verified creator could gain promotion resources from the Element platform, at least including:</p>
                                <ul className="list">
                                    <li><DotIcon />
                                        <span> Collection Verified;</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Banner Promotion;</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Trading&Listing rewards (possible);</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Social Media promotion;</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> TwitterSpace Invitation;</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Qualified analysis article;</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Collection Invitation Rebate Link;</span>
                                    </li>
                                </ul>
                                <p className="st2">2.How to apply a verified creator?</p>
                                <p>Application Link:
                                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSe2pJZ3i8SgAuEqaynp9CmZ6HXG-_51LK-D6ImDK4XvSYcuug/viewform?usp=sf_link" target='_blank' rel="noreferrer">https://docs.google.com/forms/d/e/1FAIpQLSe2pJZ3i8SgAuEqaynp9CmZ6HXG-_51LK-D6ImDK4XvSYcuug/viewform?usp=sf_link</a>
                                </p>
                            </div>
                            <div className="content-details">
                                <h6>D. Volunteer</h6>
                                <p className="st2">1.Why join Element DAO as volunteers？</p>
                                <ul className="list">
                                    <li><DotIcon />
                                        <span> Full education for hard-working volunteers, you can learn NFT knowledge from 0 to professional and harvest your first web3 resume;</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> We have a complete promotion route and those with strong ability can become the head of the department soon;</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> We have a complete volunteer token incentive mechanism, and also a lot of WL for high-quality NFT projects;</span>
                                    </li>
                                </ul>
                                <p className="st2">2.Who can be Element DAO volunteers?</p>
                                <ul className="list">
                                    <li><DotIcon />
                                        <span> Who are interested in contributing to Element DAO;</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Moderates or holders of blue-chip NFT collections;</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Willing to learn and have basic knowledge of trading NFTs;</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Bilingual Languages read and write;</span>
                                    </li>
                                </ul>
                                <p className="st2">3.Volunteer Rights and Duties</p>
                                <p className="st2">There is a dedicated volunteer task section. You can gain corresponding points by completing these tasks, which will eventually be exchanged for ELE tokens. After joining the volunteer team, you can freely choose the corresponding department, complete the department's tasks, and submit the corresponding work certificate. Meanwhile, you can actively participate in collective activities, Element DAO-related proposals, voting, etc. Existing Volunteer Departments:</p>
                                <ul className="list">
                                    <li><DotIcon />
                                        <span> Project team: BD work, seeking great NFT collections to cooperate;</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Publicity Team: link to KOLs, to public Element DAO and Element events, at least including products, cooperations, and function updates;</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Content group: output Element DAO-related content on social media;</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Discord group: organize Discord events and active communities;</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> AMA Group: responsible for Element DAO AMA-related work, invite, design and article spread</span>
                                    </li>
                                </ul>
                                <p>4.Application Link:
                                    <a href="https://docs.google.com/forms/d/e/1FAIpQLScp9iERTvR1N8KyP7JNeNECGyddqhrisTb2q5rigS0cUwLLww/viewform?usp=sf_link" target='_blank' rel="noreferrer">https://docs.google.com/forms/d/e/1FAIpQLScp9iERTvR1N8KyP7JNeNECGyddqhrisTb2q5rigS0cUwLLww/viewform?usp=sf_link</a>
                                </p>
                            </div>
                            <div className="content-details">
                                <h6>Tokenomics</h6>
                                <p>1.ELE is Element Platform Utility token. ELE Token not only serves the platform traders, but also empowers the Element DAO ecosystem.</p>
                                <p className="st2">Element DAO Governance Contributor Bonus</p>
                                <p>1% of the total ELE Token economic model will be used as a reward for Element DAO governance.</p>
                                <p className="st2">3.ELE Tokenomics</p>
                                <p>ELE token is 2,000,000,000 </p>
                            </div>
                            <div className="content-details">
                                <h6>Element Official NFT</h6>
                                <p>1.Element Official NFT is an equity voucher of Element DAO.</p>
                                <p>2.Element Official NFT holders will receive the equivalent ELE Token airdrop.</p>
                                <p>3.All proceeds from Element Official NFT will be used as start-up capital for Element DAO to promote ELE Token market liquidity.</p>
                                <p>4.Element will repurchase Element Official NFT by commission fee revenue.</p>
                                <p>5.All proposers should have an Element Official NFT.</p>
                            </div>
                            <div className="content-details">
                                <h6>Roadmap</h6>
                                <p>In Q3 2022, the Element DAO will be officially launched, and before the ELE Token is officially launched, the Element product team and the community will exercise the decision-making power on behalf of the Element DAO; at the same time, the Element DAO proposer will be sought in the community through a recommendation system.</p>
                                <p>In Q4 2022, after the Token is officially launched, Element DAO proposers have the right to initiate proposals; Element DAO members have the right to vote on proposals by holding ELE Token.</p>
                                <p>In Q1 2023, Element DAO enters the third phase and Element DAO members all have the right to propose and vote on Element's economic model and innovation events.</p>
                            </div>
                            <div className="content-details mb-41">
                                <h6>Not The End</h6>
                                <p>The Element DAO is just beginning.</p>
                            </div>
                            <div className="wrap-widget">
                                <div className="widget widget-tag style-2" />
                                <div className="widget widget-social style-2">
                                    <h6 className="title-widget">Share:</h6>
                                    <ul>
                                        <li>
                                            <a href={`https://twitter.com/share?text=${`Why Element DAO?`}&url=${encodeURI('https://www.eledao.io/eledao-details')}`} target="_blank" rel="noreferrer">
                                                <i className="fab fa-twitter"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </article>         
                    </div>
                </div>
            </section>
        </div>
        
    );
}

export default BlogEleDao;