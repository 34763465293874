const dataFaq = [
    {
        id: 1,
        title: 'What is the Responsibility of Element DAO?',
        content: '',
        // show: 'show',
        list: [
            {
                text: '· Launch $ELE tokenomics;'
            },
            {
                text: '· Vote on particular $ELE parameters;'
            },
            {
                text: '· Decide on treasure where to support;'
            },
            {
                text: '· Empower NFT through Web2&3;'
            },
        ]
    },
    {
        id: 2,
        title: 'Who will be Element DAO Member?',
        content: '',
        list: [
            {
                text: "· Team&Investor;"
            },
            {
                text: "· Element Pass holders (User, Creator, Influencer);"
            },
            {
                text: "· ..."
            },
        ],
    },
    {
        id: 3,
        title: 'What is the difference between Element DAO member and Element Pass holder?',
        content: 'Element Pass holders are Element DAO members, which means that Element Pass holders have the same govern rights as Element DAO holders, more than that, Element Pass holders have extra product privileges and $ELE token bonus.',
    },
    {
        id: 4,
        title: 'What are the core utilities of Element Pass?',
        content: '',
        list: [
            {
                text: "· Govern rights;"
            },
            {
                text: "· Token rewards;"
            },
            {
                text: "· Continually increasing product privileges;"
            },
        ],
    },
    {
        id: 5,
        title: 'What is ELE Pass’s future?',
        content: 'Element Pass holders are Element high-value users who will continually share the best product privileges and long-term benefits.',
    },
]

export default dataFaq;