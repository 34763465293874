import React from 'react';
import { Link } from 'react-router-dom';

import DotIcon from '../components/dotIcon'


function BlogEleDao(props) {
    return (
        <div className='post-details'>
            <section className="page-title" />
            <section className="tf-section detail">
                <div className="container">
                    <div className="row">
                        <article className="article" data-aos="fade-in" data-aos-duration="800">
                            <div className="post p-b23">
                                <h4 className="title"><Link to="#">Volunteer</Link></h4>
                            </div>
                            <div className="content-details mb-41">
                                <p className="st2">1.Why join Element DAO as volunteers？</p>
                                <ul className="list">
                                    <li><DotIcon />
                                        <span> Full education for hard-working volunteers, you can learn NFT knowledge from 0 to professional and harvest your first web3 resume;</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> We have a complete promotion route and those with strong ability can become the head of the department soon;</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> We have a complete volunteer token incentive mechanism, and also a lot of WL for high-quality NFT projects;</span>
                                    </li>
                                </ul>
                                <p className="st2">2.Who can be Element DAO volunteers?</p>
                                <ul className="list">
                                    <li><DotIcon />
                                        <span> Who are interested in contributing to Element DAO;</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Moderates or holders of blue-chip NFT collections;</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Willing to learn and have basic knowledge of trading NFTs;</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Bilingual Languages read and write;</span>
                                    </li>
                                </ul>
                                <p className="st2">3.Volunteer Rights and Duties</p>
                                <p className="st2">There is a dedicated volunteer task section. You can gain corresponding points by completing these tasks, which will eventually be exchanged for ELE tokens. After joining the volunteer team, you can freely choose the corresponding department, complete the department's tasks, and submit the corresponding work certificate. Meanwhile, you can actively participate in collective activities, Element DAO-related proposals, voting, etc. Existing Volunteer Departments:</p>
                                <ul className="list">
                                    <li><DotIcon />
                                        <span> Project team: BD work, seeking great NFT collections to cooperate;</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Publicity Team: link to KOLs, to public Element DAO and Element events, at least including products, cooperations, and function updates;</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Content group: output Element DAO-related content on social media;</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> Discord group: organize Discord events and active communities;</span>
                                    </li>
                                    <li><DotIcon />
                                        <span> AMA Group: responsible for Element DAO AMA-related work, invite, design and article spread</span>
                                    </li>
                                </ul>
                                <p>4.Application Link:
                                    <a href="https://docs.google.com/forms/d/e/1FAIpQLScp9iERTvR1N8KyP7JNeNECGyddqhrisTb2q5rigS0cUwLLww/viewform?usp=sf_link" target='_blank' rel="noreferrer">https://docs.google.com/forms/d/e/1FAIpQLScp9iERTvR1N8KyP7JNeNECGyddqhrisTb2q5rigS0cUwLLww/viewform?usp=sf_link</a>
                                </p>
                            </div>
                        </article>
                    </div>
                </div>
            </section>
        </div>
        
    );
}

export default BlogEleDao;