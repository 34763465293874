
import {React , useEffect} from 'react';
import AOS from 'aos';
import { Route, Routes } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';
import { ToastContainer, Slide } from './components/Toast';
import '../src/assets/font/font-awesome.css'
import routes from './pages';
import Page404 from './pages/404';
import { Web3OnboardProvider, init, useConnectWallet } from '@web3-onboard/react'

import injectedModule from '@web3-onboard/injected-wallets'

const INFURA_ID = '9aa3d95b3bc440fa88ea12eaa4456161'
const mainnet = {
  id: '0x1',
  token: 'ETH',
  label: 'Ethereum',
  rpcUrl: `https://mainnet.infura.io/v3/${INFURA_ID}`
}
const goerli = {
  id: '0x5',
  token: 'ETH',
  label: 'Goerli',
  rpcUrl: `https://goerli.infura.io/v3/${INFURA_ID}`
}
const bsctest = {
  id: '0x61',
  token: 'ETH',
  label: 'Binance Smart Chain Testnet',
  rpcUrl: `https://data-seed-prebsc-1-s1.binance.org:8545`
}

const chains = process.env.NODE_ENV === 'production' ? [mainnet] : [mainnet, bsctest]
const wallets = [injectedModule()]
const web3Onboard = init({
  connect: {
    autoConnectAllPreviousWallet: true
  },
  wallets,
  chains,
  accountCenter: {
    desktop: {
      position: 'topRight',
      enabled: true,
      minimal: false
    },
    mobile: {
      enabled: false
    }
  },
  appMetadata: {
    name: 'Element DAO',
    description: 'Element DAO',
  },
  theme: 'dark'
})

function App() {

    useEffect(() => {
        AOS.init({
          duration : 2000
        });
      }, []);

    return (
      <>
        <Web3OnboardProvider web3Onboard={web3Onboard}>
          <Header />
          <Routes>
              {
                  routes.map((data,idx) => (
                      <Route key={idx} path={data.path} element={data.component} exact />
                  ))
              }

              <Route path='*' element={<Page404 />} />
          </Routes>
          <Footer />
        </Web3OnboardProvider>

        <ToastContainer
          position="top-center"
          theme={'dark'}
          transition={Slide}
          autoClose={6000}
          hideProgressBar
        />
      </>
    );
}

export default App;
