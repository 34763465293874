import img1 from '../images/background/benefits-1.svg'
import img2 from '../images/background/benefits-2.svg'
import img3 from '../images/background/benefits-3.svg'
import img4 from '../images/background/benefits-4.svg'
import img5 from '../images/background/benefits-5.svg'
import img6 from '../images/background/benefits-6.svg'
import img7 from '../images/background/benefits-7.svg'
import img8 from '../images/background/benefits-8.svg'
import img9 from '../images/background/benefits-9.svg'

const dataFeature = [
    {
        id: 1,
        title: 'Identity',
        desc: "Element DAO Member",
        img: img1
        // class: 'active'
    },
    {
        id: 2,
        title: 'Exclusive Features',
        desc: "Pro Features, Trading Tools, Bots...",
        img: img2
    },
    {
        id: 3,
        title: 'Staking',
        desc: "Staking pass to recieve $ELE token",
        img: img3
    },
    {
        id: 4,
        title: 'Growth Pass',
        desc: "User loyalty rewards",
        img: img4
    },
    {
        id: 5,
        title: 'VIP User',
        desc: "Fee discounts",
        img: img5
    },
    {
        id: 6,
        title: 'Eco Airdrop',
        desc: "WLs, NFTs, Tokens from eco partners",
        img: img6
    },
    {
        id: 7,
        title: 'Launchpad',
        desc: "Priority access, Exclusive rounds",
        img: img7
    },
    {
        id: 8,
        title: 'Marketing Pass',
        desc: "Partner marketing events (EPG only)",
        img: img8
    },
    {
        id: 9,
        title: '?',
        desc: "...",
        img: img9
    },
]

export default dataFeature;